@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;1,500&display=swap');

:root {
    --primary-color: #F8A41B;
    --white-color: var(--white-color);
    --dark-color: #000;
}

html {
    scroll-behavior: smooth;
    --white: #fff;
    --dark: #000;
    --primary: #f8a41b;
    --primary-hover: #fed216;
}

body {
    position: relative;
    font-size: 16px;
    font-family: 'Poppins' !important;
    letter-spacing: 1px;
    color: #fff;
    max-width: 1920px;
    margin: 0 auto !important;
}

@media only screen and (max-width:1500px) {
    body {
        font-size: 15px;
    }
}

@media only screen and (max-width:1500px) and (min-width:1200px) {
    body {
        font-size: 14px;
    }
}

@media only screen and (max-width:991px) {
    body {
        font-size: 14px;
    }
}

*,
::after,
::before {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

input:focus,
textarea:focus,
select:focus,
button:focus {
    border-color: rgba(242, 126, 127, .6) !important;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 1px rgba(242, 126, 127, .6) !important;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 1px rgba(242, 126, 127, .6) !important;
}

.btn{
    border-radius: 99px !important;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    -webkit-border-radius: 0;
    background-position: calc(100% - 8px) 50%;
    background-repeat: no-repeat;
    background-size: 6px;
    padding-left: 10px;
    padding-right: 18px !important
}

a {
    text-decoration: none;
}

a:hover {
    color: var(--primary);
    text-decoration: none !important;
}

img,
iframe {
    max-width: 100%
}

img {
    width: 100%;
    height: auto
}

ul {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0
}

.container-wrap {
    max-width: 1800px
}

.form-control:disabled,
.form-control[readonly] {
    background-color: #fefefe
}

.y-box-img {
    position: relative;
    display: block;
    width: 100%;
    padding-bottom: 100%;
    overflow: hidden
}

.y-box-img .link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0
}

.y-box-img img {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    max-width: 100%;
    max-height: 100%;
    width: auto !important;
    height: auto !important
}

.y-box-zoom {
    -webkit-transition: all .6s;
    transition: all .6s;
    width: 100%;
    height: 100%;
    position: absolute
}

.y-box-zoom:hover {
    -webkit-transform: scale(1.05);
    transform: scale(1.05)
}

.box-img-flex,
.gameplay-section .gameplay-slider .item .box-content .box-right .icon-game {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    overflow: hidden
}

.box-img-flex.y-img-left,
.gameplay-section .gameplay-slider .item .box-content .box-right .y-img-left.icon-game {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start
}

.box-img-flex img,
.gameplay-section .gameplay-slider .item .box-content .box-right .icon-game img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%
}

.y-text-editor * {
    max-width: 100%
}

.y-text-editor blockquote {
    margin: 0 0 1.25em;
    padding: 0 1.25em 0 1.875em;
    border-left: 2px solid #111;
    font-style: italic
}

.y-text-editor p {
    margin-bottom: 10px
}

.y-text-editor img {
    max-width: 100%;
    height: auto !important;
    display: initial
}

.y-text-editor iframe {
    max-width: 100%
}

.y-text-editor table {
    width: 100%
}

.y-text-editor i {
    font-style: italic
}

.y-text-editor u {
    text-decoration: underline
}

.y-text-editor a {
    text-decoration: underline
}

.y-text-editor b,
.y-text-editor strong {
    font-weight: 700
}

.y-text-editor ul {
    padding-left: 30px;
    list-style-type: disc
}

.y-text-editor ul li {
    display: list-item
}

.y-text-editor ol {
    padding-left: 30px;
    list-style-type: decimal
}

.y-text-editor ol li {
    display: list-item
}

.box-btn-main {
    position: relative;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: 100%;
    text-align: center;
}

.btn-whitepaper,
.btn-play {
    min-width: 100%;
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    padding: 25px;
    text-align: center;
    transition: background-color .5s;
    border-radius: 3px;
    border: 1px solid #fff;
    height: 40px;
}

.btn-whitepaper:hover,
.btn-play:hover {
    background-color: var(--primary);
    border-color: var(--primary);
}

.y-mobile-menu ul li .btn-connect-wallet {
    position: relative;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    padding: 5px 15px;
    text-align: center;
    background-color: var(--primary);
    -webkit-transition: background-color .5s;
    transition: background-color .5s;
    border-radius: 3px;
    height: 40px;
}

.box-btn-main .btn-market-wrap {
    position: relative;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    padding: 5px 15px;
    text-align: center;
    background-color: #2691ca;
    -webkit-transition: background-color .5s;
    transition: background-color .5s;
    border-radius: 3px;
    height: 40px;
}

@media only screen and (max-width:1500px) {
    .box-btn-main .btn-main {
        padding: 5px 15px;
    }
}

@media only screen and (max-width:1500px) and (min-width:1200px) {
    .box-btn-main .btn-main {
        padding: 5px 15px;
    }
}

@media only screen and (max-width:767px) {
    .box-btn-main .btn-main {
        font-size: 14px;
        padding: 5px 15px;
    }
}

.box-btn-main .btn-main.btn-yellow {
    background-color: #fcfe15;
    color: var(--dark);
    padding: 10px 45px
}

@media only screen and (max-width:1500px) {
    .box-btn-main .btn-main.btn-yellow {
        padding: 5px 35px
    }
}

@media only screen and (max-width:1500px) and (min-width:1200px) {
    .box-btn-main .btn-main.btn-yellow {
        padding: 5px 30px
    }
}

.box-btn-main .btn-main.btn-yellow:before {
    border-right: 15px solid #fcfe15
}

.box-btn-main .btn-main.btn-yellow:after {
    border-left: 15px solid #fcfe15
}

.box-btn-main .btn-main span {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    line-height: 1;
    color: #fff;
    font-weight: 400;
}

.about-section .content-about .box-info .box-download .item .box-btn-main a {
    display: block;
    transition: .4s ease-in;
}

.about-section .content-about .box-info .box-download .item .box-btn-main a:hover {
    transform: scale(1.1);
}


.modal-dialog {
    min-height: calc(100vh - 60px);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

@media(max-width:767px) {
    .modal-dialog {
        min-height: calc(100vh - 20px)
    }
}

body.fixed .header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

@media only screen and (max-width:1500px) and (min-width:1200px) {
    body.fixed .box-section {
        padding-top: 30px
    }
}

.loader-wrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #242f3f;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    z-index: 99999
}

.loader-wrapper .content-loader {
    text-align: center
}

.loader-wrapper .content-loader .box-img-flex,
.loader-wrapper .content-loader .gameplay-section .gameplay-slider .item .box-content .box-right .icon-game,
.gameplay-section .gameplay-slider .item .box-content .box-right .loader-wrapper .content-loader .icon-game {
    height: 120px;
    margin-bottom: 10px
}

@media only screen and (max-width:767px) {

    .loader-wrapper .content-loader .box-img-flex,
    .loader-wrapper .content-loader .gameplay-section .gameplay-slider .item .box-content .box-right .icon-game,
    .gameplay-section .gameplay-slider .item .box-content .box-right .loader-wrapper .content-loader .icon-game {
        height: 100px
    }
}

.loader-wrapper .content-loader .text-loading {
    width: 100%
}

.section-full {
    position: relative
}

@media only screen and (max-width:991px) {
    .section-full {
        padding-top: 50px;
        padding-bottom: 50px;
        overflow: hidden
    }
}

.section-full.active .txt-animate {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
}

@media only screen and (max-width:991px) {
    .section-full .box-section {
        padding-top: 0
    }
}

.section-full .txt-animate {
    position: relative;
    -webkit-transition: all 1200ms;
    transition: all 1200ms;
    opacity: 0;
    -webkit-transform: translate3d(50%, 0, 0);
    transform: translate3d(50%, 0, 0)
}

@media only screen and (max-width:991px) {
    .fullpage {
        padding-top: 70px
    }
}

@media only screen and (max-width:575px) {
    .fullpage {
        padding-top: 60px
    }
}

.box-title-main {
    position: relative;
    text-align: center;
    margin-bottom: 20px;
    z-index: 5;
    pointer-events: none;
}

@media only screen and (max-width:1500px) {
    .box-title-main {
        margin-bottom: 30px;
    }
}

.box-title-main .title-develop {
    margin-top: 10px;
}

.box-title-main .title-main {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 55px;
    font-weight: 700;
    margin-bottom: 12px;
    text-transform: uppercase;
    line-height: 1.5em;
    color: var(--primary-color) !important;
    letter-spacing: 5px;
    text-shadow: 0px 4px 4px rgb(255 255 255 / 35%);
}


.title-main.text-white {
    color: var(--white) !important;
}

@media only screen and (max-width:1700px) and (min-width:1490px) {
    .box-title-main .title-main {
        font-size: 40px;
    }
}

@media only screen and (max-width:1500px) and (min-width:1200px) {
    .box-title-main .title-main {
        font-size: 40px;
    }
}

@media only screen and (max-width:1190px) {
    .box-title-main .title-main {
        background: initial;
        -webkit-background-clip: initial;
        -webkit-text-fill-color: initial;
        font-size: 34px
    }
}

@media only screen and (max-width:991px) {
    .box-title-main .title-main {
        font-size: 32px
    }
}

@media only screen and (max-width:767px) {
    .box-title-main .title-main {
        font-size: 32px;
        margin-bottom: 15px
    }
}

@media only screen and (max-width:575px) {
    .box-title-main .title-main {
        font-size: 24px;
        line-height: 1.5em;
    }
}

.box-title-main .desc-main {
    font-size: 20px;
    color: var(--white);
    padding: 0 15px
}

.gameplay-section .box-title-main .desc-main,
.gameplay-section .box-title-main .title-main,
.roadmap-section .box-title-main .title-main {
    color: var(--white);
}

@media only screen and (max-width:1500px) {
    .box-title-main .desc-main {
        font-size: 16px
    }
}

@media only screen and (max-width:1500px) and (min-width:1200px) {
    .box-title-main .desc-main {
        font-size: 16px
    }
}

@media only screen and (max-width:991px) {
    .box-title-main .desc-main {
        font-size: 14px
    }
}

@media only screen and (max-width:575px) {
    .box-title-main .desc-main {
        font-size: 13px
    }
}

@media only screen and (max-width:575px) {
    .box-title-main .desc-main br {
        display: none
    }
}

.box-1700 {
    margin: 0 auto;
    padding: 0 100px
}

@media only screen and (max-width:1500px) {
    .box-1700 {
        padding: 0 70px
    }
}

@media only screen and (max-width:1500px) {
    .box-1700 {
        max-width: 1500px
    }
}

@media only screen and (max-width:1190px) {
    .box-1700 {
        padding: 0 40px
    }
}

@media only screen and (max-width:767px) {
    .box-1700 {
        padding: 0 20px
    }
}

@media only screen and (max-width:575px) {
    .box-1700 {
        padding: 0 15px
    }
}

.box-mobile {
    display: none;
}

@media only screen and (max-width:991px) {
    .box-mobile {
        display: block
    }
}

.about-section {
    position: relative;
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 100px 0;
    min-height: 100vh;
}

@media only screen and (max-width:991px) {
    .about-section {
        padding-bottom: 0;
        padding-top: 0;
        overflow: inherit;
    }

}

@media only screen and (max-width:767px) {
    .about-section {
        padding-bottom: 0;
        padding-top: 0;
        height: auto;
    }
}

.about-section .box-section .box-img-about {
    position: absolute;
    bottom: -45px;
    right: 3px;
    content: '';
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
}

@media only screen and (max-width:991px) {
    .about-section .box-section .box-img-about {
        bottom: -10px;
        right: 0;
    }
}

.about-section .content-about {
    z-index: 3;
}

@media only screen and (max-width:991px) {
    .about-section .content-about {}
}

@media only screen and (max-width:480px) {
    .about-section .content-about {
        position: relative;
        width: 100%;
        padding: 20px;
    }

    .about-section .box-section {
        display: flex;
        flex-direction: column-reverse;
    }
}


.about-section .content-about .box-info {
    position: relative;
    padding-top: 40px;
    padding-left: 40px;
    padding-right: 40px;
    width: 60%;
    margin: 0 auto;
    z-index: 10;
}

.about-section .content-about .box-info .car_home {
    transform: translateY(-50px);
}

@media only screen and (max-width:991px) {
    .about-section .content-about .box-info .car_home {
        transform: translateY(-40px);
    }
}

@media only screen and (max-width:1190px) {
    .about-section .content-about .box-info {
        padding-left: 15px;
        padding-right: 15px
    }
}

@media only screen and (max-width:991px) {
    .about-section .content-about .box-info {
        padding-top: 0;
        width: 100%;
        text-align: center
    }
}

.about-section .content-about .box-info .box-title-racer {
    position: relative;
    animation: typing 2s steps(22), blink .5s step-end infinite alternate;
    white-space: nowrap;
    overflow: hidden;
    text-align: center;
}

.box-title-racer {
    color: var(--primary-color);
    letter-spacing: 5px;
    text-shadow: 0px 4px 4px rgba(0, 255, 255, 0.35);
}

.about-section .content-about .box-info .box-title-racer .title-racer span {
    color: #F8A41B;
}

@media only screen and (max-width:991px) {
    .about-section .content-about .box-info .box-title-racer {
        margin: 0 auto
    }
}

.about-section .content-about .box-info .box-title-racer .title-racer {
    position: relative;
    margin-bottom: 5px;
    font-size: 72px;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 1;
    overflow: hidden;
    white-space: nowrap;
    -webkit-animation: typing .6s steps(40, end);
    animation: typing .6s steps(40, end);
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #ffffff;
    text-shadow: 0px 6px #f8a41b, 5px 6px #55b3ff;
    font-style: italic;
}

.img-play {
    height: 200px;
    z-index: 100;
    width: 100%;
}

.box-line {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    bottom: -13%;
    z-index: 10;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.box-line>img {
    position: absolute;
    z-index: 1;
}

.box-line a {
    z-index: 99;
    transition: .4s ease;
}

.box-line a:hover {
    transform: scale(1.1);
}

@-webkit-keyframes typing {
    from {
        width: 0
    }

    to {
        width: 100%
    }
}

@keyframes typing {
    from {
        width: 0
    }

    to {
        width: 100%
    }
}

@-webkit-keyframes blink-caret {

    from,
    to {
        border-color: transparent
    }

    50% {
        border-color: orange
    }
}

@keyframes blink-caret {

    from,
    to {
        border-color: transparent
    }

    50% {
        border-color: orange
    }
}

@media only screen and (max-width:1500px) {
    .about-section .content-about .box-info .box-title-racer .title-racer {
        font-size: 100px;
    }
}

@media only screen and (max-width:1190px) {
    .about-section .content-about .box-info .box-title-racer .title-racer {
        font-size: 72px
    }
}

@media only screen and (max-width:991px) {
    .about-section .content-about .box-info .box-title-racer .title-racer {
        font-size: 70px
    }
}

@media only screen and (max-width:767px) {
    .about-section .content-about .box-info .box-title-racer .title-racer {
        font-size: 54px
    }
}

.about-section .content-about .box-info .box-title-racer .title-racer.go {
    -webkit-animation: typing .5s steps(40, end);
    animation: typing .5s steps(40, end)
}

.about-section .content-about .box-info .box-title-racer .title-racer p {
    color: var(--primary);
    line-height: .7
}

.about-section .content-about .box-info .desc-racers {
    margin-bottom: 40px;
}

.about-section .content-about .box-info .desc-racers p {
    text-align: center;
    font-size: 14px;
    margin-top: 0;
}

@media only screen and (max-width:991px) {
    .about-section .content-about .box-info .desc-racers {
        margin-bottom: 20px
    }
}

@media only screen and (max-width:575px) {
    .about-section .content-about .box-info .desc-racers br {
        display: none
    }
}

.about-section .content-about .box-info .box-download {
    --spacing: 10px;
    margin-left: calc(-1 * var(--spacing));
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

@media only screen and (max-width:991px) {
    .about-section .content-about .box-info .box-download {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        margin-bottom: 10px
    }
}

@media only screen and (max-width:480px) {
    .about-section .content-about .box-info .box-download {
        --column: 1;
    }
}

.about-section .content-about .box-info .box-download .item {
    margin-left: var(--spacing);
    margin-bottom: var(--spacing);
    width: calc((100% / var(--column)) - var(--spacing));
}

@media only screen and (max-width:767px) {
    .about-section .content-about .box-info .box-download .item {
        margin-right: 10px;
        margin-bottom: 10px;
    }
}


@media only screen and (max-width:1500px) {
    .about-section .content-about .box-info .box-download .item .box-btn-main .btn-main {
        font-size: 14px
    }
}

.about-section .content-about .box-info .box-download .item .box-btn-main .btn-main span {
    width: 86px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: flex-start;
}

@media only screen and (max-width:991px) {
    .about-section .content-about .box-info .box-download .item .box-btn-main .btn-main span {
        width: 100%;
    }

    .about-section .content-about .box-info .box-download .item .box-btn-main a img {
        width: 50%;
    }
}

@media only screen and (max-width:767px) {
    .about-section .content-about .box-info .box-download .item .box-btn-main i {
        font-size: 30px
    }
}

.about-section .content-about .box-info .chain-table .tg {
    border-collapse: collapse;
    border-spacing: 0;
    background-color: rgba(111, 66, 193, .3);
    -webkit-box-shadow: 0 0 100px 3px #b690fc;
    box-shadow: 0 0 100px 3px #b690fc;
    width: calc(100% - 35px)
}

@media only screen and (max-width:991px) {
    .about-section .content-about .box-info .chain-table .tg {
        margin: auto;
        -webkit-box-shadow: none;
        box-shadow: none;
        background-color: transparent;
        width: 100%;
        max-width: 460px
    }
}

.about-section .content-about .box-info .chain-table .tg td {
    border-color: #c7a0f7;
    border-style: solid;
    border-width: .5px
}

.about-section .content-about .box-info .chain-table .tg .tg-baqh {
    text-align: center;
    vertical-align: top;
    text-shadow: 1px 1px #6610f2;
    padding: 0 5px
}

.about-section .content-about .box-info .chain-table .tg .tg-0lax {
    text-align: left;
    vertical-align: top;
    text-shadow: 1px 1px #6610f2;
    padding: 0 5px
}

.about-section .content-about .img-about {
    position: relative;
    width: 60%;
    padding-right: 50px;
    margin-top: auto
}

.about_right_wrap {
    margin-top: 4rem;
}

@media only screen and (max-width:991px) {
    .about-section .content-about .img-about {
        width: 100%;
        padding: 0 15px
    }
}

.video-section {
    position: relative;
    background-color: #000;
    padding: 100px 80px;
    padding-top: 150px;
    min-height: 600px;
}

.video2-section {
    position: relative;
    background-color: #000;
    padding: 80px;
}

/* .about_wrap {
    display: grid;
    gap: 40px;
    grid-auto-columns: minmax(0, 1fr);
    grid-auto-flow: column;
} */

@media only screen and (max-width:991px) {
    .video-section {
        padding: 80px 30px;
    }

    .video2-section {
        padding: 50px 30px;
    }
}

@media only screen and (max-width:480px) {
    .about_wrap {
        grid-template-columns: none;
        grid-template-rows: auto;
        grid-auto-flow: row;
    }
}

.video-section .box-video {
    margin-top: 40px;
}


.video-section .box-video video {
    width: 100%;
}

.video-section2 .box-video-left,
.video-section2 .box-video-right {
    position: relative;
}

.video2-section .box-video-left {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;
}

.video2-section .box-video-left img {
    width: 75%;
}

@media screen and (max-width:480px) {
    .video-section {
        padding: 80px 10px;
    }

    .video-section .box-video {
        --column: 1;
    }

    .video-section .box-video-right {
        margin-top: 30px;
        padding: 30px 0;
    }

}

.box-video-left .play {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate3d(-50%, -50%, 0);
    transform: translate3d(-50%, -50%, 0);
    transition: all .4s ease-in;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    width: 60px;
    height: 60px;
    cursor: pointer;
    border: 2px solid var(--white);
    border-radius: 50%;
}

.box-video-left .play .icon-play {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate3d(-30%, -50%, 0);
    transform: translate3d(-30%, -50%, 0);
    border-style: solid;
    border-color: transparent var(--white);
    border-width: 10px 0 10px 12px;
}

.box-video-left .play::before,
.box-video-left .play::after {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: var(--primary);
    border-radius: 50%;
    -webkit-box-shadow: 0 0 0 0 currentColor;
    box-shadow: 0 0 0 0 currentColor;
    -webkit-animation: button-ripple 3s infinite;
    animation: button-ripple 3s infinite;
    opacity: .6;
    z-index: -1;
}

.box-video-left .lightbox {

    display: block;
    max-width: 100%;
    text-align: center;
}

.box-video-left .lightbox img {
    border-radius: 5px;
    width: 75%;
}

.box-video-right {
    padding: 30px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.box-video-right .desc-inner {
    width: 50%;
    text-transform: uppercase;
    color: var(--white);
}


.desc-inner p {
    line-height: 1.5em;
    margin-bottom: 10px;
    font-size: 18px;
}

@media only screen and (max-width: 480px) {
    .box-video-right .desc-inner {
        width: 100%;
    }

    .desc-inner p {
        margin: 0;
    }

    .box-video-left a.lightbox img {
        width: 100%;
    }

    .box-video-right {
        padding: 0;
    }
}

@media only screen and (min-width: 1200px) {
    .box-video-left .play:before {
        border-width: 8px 0 8px 10px;
    }
}

.gameplay-section {
    background: #000;
    padding: 100px 0px;
}

.game-background-overlay {
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
    opacity: 1;
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
}

.gameplay-section .box-section {
    position: relative
}

@media only screen and (max-width:767px) {
    .game-background-overlay {
        background-size: cover;
        background-position: center;
    }

    .gameplay-section .box-section .box-title-main {
        position: relative;
    }
}

@media screen and (max-width:767px) {
    .gameplay-section {
        padding-top: 30px;
    }
}

.swiper-horizontal>.swiper-pagination-progressbar,
.swiper-pagination-progressbar.swiper-pagination-horizontal {
    bottom: 0;
    top: unset;
}

.swiper-pagination-progressbar {
    background: var(--white);
}

.swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
    background-color: var(--primary);
}

.swiper-button-next,
.swiper-button-prev {
    width: 50px;
    height: 30px;
    background-color: var(--primary);
    border-radius: 5px;
    margin-top: 0;
    transform: translateY(-50%);
}

.js-pagination .swiper-pagination {
    bottom: 50%;
    transform: translateY(50%);
}

.nfts-swiper-button-prev,
.nfts-swiper-button-next {
    background-color: #2691ca;
}

.gameplay-slider .swiper-button-prev,
.gameplay-slider .swiper-button-next {
    background-color: var(--primary);
}

@media only screen and (max-width:991px) {

    .js-pagination .swiper-pagination,
    .swiper-pagination {
        display: block;
    }
}

.swiper-pagination .swiper-pagination-bullet {
    width: 10px;
    border-radius: 10px;
    height: 10px;
    background: var(--primary-color);
}

.nfts-slider .swiper-pagination .swiper-pagination-bullet {
    background-color: #F8A41B;
}

.swiper-button-prev {
    left: 30px;
}

.swiper-button-next {
    right: 30px;
}

.swiper-button-next::after,
.swiper-button-prev::after {
    font-size: 14px;
    color: #fff;
}

.gameplay-section .gameplay-slider.swiper {
    width: 100%;
}

.js-pagination {
    height: 70px;
    position: relative;
}

@media only screen and (max-width:991px) {
    .js-pagination {
        width: 90%;
        margin: 0 auto;
    }
}

.gameplay-section .gameplay-slider .item .box-content .box-left .image {
    width: 100%;
    height: 100%;
}

@media only screen and (max-width:991px) {
    .gameplay-section .gameplay-slider .item .box-content .box-left .image {
        width: 300px;
        height: 300px;
        margin: 0 auto;
    }
}

.gameplay-section .gameplay-slider .item .box-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    z-index: 2;
}

.gameplay-section .gameplay-slider .item .box-content .box-left {
    position: relative;
    width: 60%;
    padding-left: 50px;
}

@media only screen and (max-width:1700px) and (min-width:1490px) {
    .gameplay-section .gameplay-slider .item .box-content {
        max-width: 1560px;
        margin: 0 auto
    }
}

@media only screen and (max-width:1500px) and (min-width:1200px) {
    .gameplay-section .gameplay-slider .item .box-content {
        max-width: 1140px;
        margin: 0 auto
    }
}

@media only screen and (max-width:480px) {
    .gameplay-section .gameplay-slider .item .box-content {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .gameplay-section .gameplay-slider .item .box-content .box-left {
        width: 100%;
    }
}

@media only screen and (max-width:1500px) {
    .gameplay-section .gameplay-slider .item .box-content .box-left {
        padding-left: 40px
    }
}

@media only screen and (max-width:1500px) and (min-width:1200px) {
    .gameplay-section .gameplay-slider .item .box-content .box-left {
        padding-left: 0;
        width: 60%;
    }
}

@media only screen and (max-width:1190px) {
    .gameplay-section .gameplay-slider .item .box-content .box-left {
        padding-left: 0
    }
}

@media only screen and (max-width:991px) {
    .gameplay-section .gameplay-slider .item .box-content .box-left .image {
        -webkit-transform: initial;
        transform: initial
    }
}

.gameplay-section .gameplay-slider .item .box-content .box-left .image img {
    width: auto;
    height: auto;
}

@media only screen and (max-width:480px) {
    .gameplay-section .gameplay-slider .item .box-content .box-left .image {
        height: auto;
    }

    .gameplay-section .gameplay-slider .item .box-content .box-left .image img {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

.gameplay-section .gameplay-slider .item .box-content .box-right {
    position: relative;
    width: 40%;
    text-align: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: center;
    padding: 50px 15px;
}

@media only screen and (max-width:991px) {
    .gameplay-section .gameplay-slider .item .box-content .box-right {
        padding: 30px 0 40px;
        max-width: inherit;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center
    }
}

@media only screen and (max-width:767px) {
    .gameplay-section .gameplay-slider .item .box-content .box-right {
        width: 100%;
    }
}

.gameplay-section .gameplay-slider .item .box-content .box-right .box-btn-main {
    -webkit-transition: all .7s;
    transition: all .7s;
    -webkit-transform: scale(0);
    transform: scale(0)
}

.gameplay-section .gameplay-slider .item .box-content .box-right .icon-game {
    height: 140px;
    margin-bottom: 15px
}

@media only screen and (max-width:1700px) and (min-width:1490px) {
    .gameplay-section .gameplay-slider .item .box-content .box-right .icon-game {
        height: 100px;
    }
}

@media only screen and (max-width:1500px) and (min-width:1200px) {
    .gameplay-section .gameplay-slider .item .box-content .box-right .icon-game {
        height: 100px;
    }
}

@media only screen and (max-width:1190px) {
    .gameplay-section .gameplay-slider .item .box-content .box-right .icon-game {
        height: 100px;
    }
}

@media only screen and (max-width:991px) {
    .gameplay-section .gameplay-slider .item .box-content .box-right .icon-game {
        height: 100px;
    }
}

@media only screen and (max-width:767px) {
    .gameplay-section .gameplay-slider .item .box-content .box-right .icon-game {
        height: 100px;
    }
}

.gameplay-section .gameplay-slider .item .box-content .box-right .name-game {
    color: #fff;
    font-size: 38px;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 1;
    margin-bottom: 10px;
}

@media only screen and (max-width:1700px) and (min-width:1490px) {
    .gameplay-section .gameplay-slider .item .box-content .box-right .name-game {
        font-size: 28px
    }
}

@media only screen and (max-width:1500px) and (min-width:1200px) {
    .gameplay-section .gameplay-slider .item .box-content .box-right .name-game {
        font-size: 26px
    }
}

@media only screen and (max-width:1190px) {
    .gameplay-section .gameplay-slider .item .box-content .box-right .name-game {
        font-size: 25px
    }
}

@media only screen and (max-width:767px) {
    .gameplay-section .gameplay-slider .item .box-content .box-right .name-game {
        font-size: 22px
    }
}

.gameplay-section .gameplay-slider .item .box-content .box-right .level-game {
    color: #ffd43c;
    font-size: 40px;
    font-weight: 700;
    line-height: 1;
    margin-bottom: 15px
}

@media only screen and (max-width:1700px) and (min-width:1490px) {
    .gameplay-section .gameplay-slider .item .box-content .box-right .level-game {
        font-size: 30px
    }
}

@media only screen and (max-width:1500px) and (min-width:1200px) {
    .gameplay-section .gameplay-slider .item .box-content .box-right .level-game {
        font-size: 28px
    }
}

@media only screen and (max-width:1190px) {
    .gameplay-section .gameplay-slider .item .box-content .box-right .level-game {
        font-size: 22px
    }
}

.gameplay-section .gameplay-slider .item .box-content .box-right .desc-game {
    margin-bottom: 35px
}

@media only screen and (max-width:1700px) and (min-width:1490px) {
    .gameplay-section .gameplay-slider .item .box-content .box-right .desc-game {
        margin-bottom: 30px
    }
}

@media only screen and (max-width:1500px) and (min-width:1200px) {
    .gameplay-section .gameplay-slider .item .box-content .box-right .desc-game {
        margin-bottom: 24px;
        font-size: 16px
    }
}

@media only screen and (max-width:1190px) {
    .gameplay-section .gameplay-slider .item .box-content .box-right .desc-game {
        font-size: 14px;
        margin-bottom: 20px
    }
}

@media only screen and (max-width:767px) {
    .gameplay-section .gameplay-slider .item .box-content .box-right .desc-game {
        margin-bottom: 20px;
        padding: 0 15px
    }
}

@media only screen and (max-width:1190px) {
    .gameplay-section .gameplay-slider .item .box-content .box-right .desc-game br {
        display: none
    }
}

@media only screen and (max-width:575px) {
    .gameplay-section .gameplay-slider .item .box-content .box-right .desc-game br {
        display: none
    }

    .box-line {
        bottom: -6%;
    }
}

.gameplay-section .gameplay-slider .item .box-content .box-right .btn-watch {
    margin: 0 auto;
    text-transform: uppercase
}

.gameplay-section .gameplay-slider .item .box-content .box-right .btn-watch i {
    margin-right: 5px
}

.gameplay-section .gameplay-slider .item .box-content .box-right .btn-viewmore {
    color: #92ffec;
    font-weight: 700;
    margin-right: 0;
    display: block;
    text-align: right;
    margin-top: -22px;
    margin-right: 0;
}

@media only screen and (max-width:1500px) {
    .gameplay-section .gameplay-slider .item .box-content .box-right .btn-viewmore {
        font-size: 14px;
        margin-top: -18px;
        margin-right: 20px;
    }
}

@media only screen and (max-width:991px) {
    .gameplay-section .gameplay-slider .item .box-content .box-right .btn-viewmore {
        margin-right: 0;
        margin-top: 25px;
        text-align: center;
    }
}

.nfts-section {
    background-color: #000;
    padding: 100px 80px;
    padding-bottom: 0;
}

.nfts-section .box-section {
    position: relative;
}

@media only screen and (max-width:991px) {
    .nfts-section .box-section {
        padding-bottom: 40px;
    }
}

@media screen and (max-width:480px) {
    .nfts-section {
        padding: 50px 10px;
        min-height: auto;
    }

}

.nfts-section .nfts-slider {
    position: relative;
    margin: 0 auto;
    padding-bottom: 80px
}

@media only screen and (max-width:1700px) and (min-width:1490px) {
    .nfts-section .nfts-slider {
        padding-bottom: 60px
    }
}

@media only screen and (max-width:1500px) and (min-width:1200px) {
    .nfts-section .nfts-slider {
        padding-bottom: 30px
    }
}

@media only screen and (max-width:1190px) {
    .nfts-section .nfts-slider {
        padding-bottom: 30px
    }
}

.nfts-section .nfts-slider .item {
    text-align: center;
    position: relative
}

.nfts-section .nfts-slider .item .item-nfts {
    position: relative
}

.nfts-section .nfts-slider .item .img-nfts {
    position: relative;
    margin-bottom: 2px;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 1rem;
}

.nfts-section .nfts-slider .item .img-nfts .img-1 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100%
}

.nfts-section .nfts-slider .item .img-nfts .img-1 img {
    object-fit: fill;
    height: 175px;
}

.nfts-section .box-btn-main {
    position: absolute;
    top: 40px;
    right: 50px;
    z-index: 6;
    width: max-content;
}

@media only screen and (max-width:1500px) {
    .nfts-section .box-btn-main {
        top: 30px;
        right: 80px
    }
}

@media only screen and (max-width:991px) {
    .nfts-section .box-btn-main {
        position: relative;
        right: 0;
        top: 0;
        margin: 10px auto 0;
    }

    .nfts-section .nfts-slider .item .img-nfts .img-1 img {
        height: auto;
    }
}

.ts-fadeInRight {
    -webkit-animation-name: ts-fadeInRight;
    animation-name: ts-fadeInRight
}

@-webkit-keyframes ts-fadeInRight {
    from {
        opacity: 0;
        -webkit-transform: translate3d(1.25rem, 0, 0);
        transform: translate3d(1.25rem, 0, 0)
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
}

@keyframes ts-fadeInRight {
    from {
        opacity: 0;
        -webkit-transform: translate3d(1.25rem, 0, 0);
        transform: translate3d(1.25rem, 0, 0)
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
}

.coin-section {
    background-color: #000;
    padding: 100px 80px;
}

@media only screen and (max-width:991px) {
    .coin-section {
        padding: 50px 30px;
    }
}

@media only screen and (max-width:767px) {
    .coin-section {
        padding: 50px 15px;
    }
}

.coin-section .bg-section {
    position: relative
}

.coin-section .bg-section:before,
.coin-section .bg-section:after {
    position: absolute;
    content: "";
    top: -100px;
    width: 35%
}

@media only screen and (max-width:1500px) {

    .coin-section .bg-section:before,
    .coin-section .bg-section:after {
        top: -70px
    }
}

@media only screen and (max-width:767px) {

    .coin-section .bg-section:before,
    .coin-section .bg-section:after {
        top: 0;
        width: 45%
    }
}

.coin-section .bg-section:before {
    left: 0;
    height: 100%;
    background-repeat: no-repeat;
    background-size: contain
}

.coin-section .bg-section:after {
    right: 0;
    height: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    z-index: 0
}

.coin-section .content-coin {
    --spacing: 15px;
    --column: 1;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 0 auto;
    z-index: 2
}

.coin-section .content-coin .list-item-coin.list-1 .box-left {
    width: 60%
}

.coin-section .content-coin .list-item-coin.list-1 .box-right {
    width: 40%
}

.coin-section .content-coin .list-item-coin.list-2 .item-coin {
    margin-bottom: 30px
}

@media only screen and (max-width:991px) {
    .coin-section .content-coin .list-item-coin.list-2 .item-coin {
        width: calc(50% - 15px);
        margin-bottom: 0
    }
}

.coin-section .content-coin .list-item-coin.list-2 .item-coin:last-child {
    margin-bottom: 0
}

@media only screen and (max-width:991px) {
    .coin-section .content-coin .list-item-coin.list-2 {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        width: 100%
    }
}

.coin-section .content-coin .list-item-coin.list-3 {
    display: grid;
    grid-template-columns: 45% 45%;
    align-items: center;
    justify-content: space-between;
}

.coin-section .content-coin .list-item-coin.list-3 .item-coin {
    margin-bottom: var(--spacing);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

@media only screen and (max-width:1200px) {
    .coin-section .content-coin .list-item-coin.list-3 .item-coin {
        min-height: 220px;
    }

    .coin-section .content-coin .box .item-coin .box-inline .img-coin {
        width: 100% !important;
    }
}


@media screen and (max-width:991px) {
    .coin-section .content-coin .list-item-coin.list-3 {
        grid-template-columns: 50% 50%;
        justify-content: space-between;
    }

    .coin-section .content-coin .list-item-coin.list-3 .item-coin {
        width: 100%;
        min-height: 180px;
    }
}


@media screen and (max-width:767px) {
    .coin-section .content-coin .list-item-coin.list-3 {
        grid-template-columns: 45% 45%;
        justify-content: space-between;
    }

    .coin-section .content-coin .list-item-coin.list-3 .item-coin {
        width: 100%;
        min-height: 180px;
        background-size: contain;
    }
}


@media only screen and (max-width:480px) {
    .coin-section .content-coin .list-item-coin.list-3 {
        grid-template-columns: unset;
        grid-template-rows: 100%;
        display: block;
    }

    .coin-section .content-coin .list-item-coin.list-3 .item-coin {
        margin: 0;
        min-height: 100px;
        margin-bottom: 0px;
        background-size: contain;
    }
}

.coin-section .content-coin .box-first {
    width: 580px;
}

.coin-section .content-coin .box.box.box-last {
    width: 100%;
}

@media screen and (max-width:991px) {
    .coin-section .content-coin .box {
        width: 100%;
        text-align: center;
    }

    .coin-section .content-coin .box-first {
        width: 580px;
        text-align: center;
    }
}

@media screen and (max-width:767px) {
    .coin-section .content-coin .box {
        width: 100%;
        text-align: center;
    }

    .coin-section .content-coin .box-first {
        width: 100%;
        text-align: center;
    }
}

@media only screen and (max-width:480px) {
    .coin-section .content-coin .box {
        width: 100%;
        text-align: center;
    }
}

.coin-section .content-coin .box.box-middle {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
}

.coin-section .content-coin .box.box-middle .arrow-1,
.coin-section .content-coin .box.box-middle .arrow-2 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    padding: 10px
}

.coin-section .content-coin .box.box-middle .arrow-2 {
    align-items: flex-start;
}

.coin-section .content-coin .box.box-middle .arrow-1 img {
    width: 100%;
    height: 100px !important;
    transform: translateY(20px);
}

.coin-section .content-coin .box.box-middle .arrow-1 img,
.coin-section .content-coin .box.box-middle .arrow-2 img {
    height: 100%
}

.coin-section .content-coin .box.box-middle .arrow-2 img {
    width: 100%;
    transform: translateY(0px) rotate(90deg);
    transform-origin: center;
}

.coin-section .content-coin .box.box-middle .arrow-1.arrow-single img,
.coin-section .content-coin .box.box-middle .arrow-2.arrow-single img {
    height: auto
}

@media only screen and (max-width:991px) and (max-width:991px) {
    .coin-section .content-coin .box.box-middle .arrow-1 img {
        height: auto
    }
}

.coin-section .content-coin .box.box-middle .logo-coin {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    box-shadow: 0 0 30px #f8a41b;
    border-radius: 99%;
}

.coin-section .content-coin .box.box-middle .logo-coin img {
    width: 200px;
    height: 200px;
    object-fit: contain;
}

@media only screen and (max-width:991px) {
    .coin-section .content-coin .box.box-middle .logo-coin {
        max-width: 400px;
        margin: 0;
    }

    .coin-section .content-coin .box.box-middle .logo-coin img{
        width: 150px;
        height: 150px;
    }
}

.coin-section .content-coin .box.box-middle .logo-coin .title-coin {
    color: #ffd955;
    font-weight: 700;
    font-size: 40px;
    text-transform: uppercase;
    position: absolute;
    top: 20px;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center
}

@media only screen and (max-width:1500px) {
    .coin-section .content-coin .box.box-middle .logo-coin .title-coin {
        font-size: 32px
    }
}

@media only screen and (max-width:1190px) {
    .coin-section .content-coin .box.box-middle .logo-coin .title-coin {
        font-size: 28px
    }
}

@media only screen and (max-width:991px) {
    .coin-section .content-coin .box.box-middle .logo-coin .title-coin {
        top: 60px;
        font-size: 30px
    }
}

@media only screen and (max-width:767px) {
    .coin-section .content-coin .box.box-middle .logo-coin .title-coin {
        top: 50px;
        font-size: 28px
    }
}

@media only screen and (max-width:575px) {
    .coin-section .content-coin .box.box-middle .logo-coin .title-coin {
        font-size: 22px;
        top: 40px
    }
}

.coin-section .content-coin .box.box-middle .logo-coin .title-coin.color-green {
    color: #3dfe8b
}

@media only screen and (max-width:991px) {
    .coin-section .content-coin .box.box-middle .arrow-2 {
        position: relative;
        top: -40px;
        left: 0;
        bottom: 0;
        right: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        height: 140px;
        width: 80%;
        margin: 0 auto;
        z-index: -1
    }

    .coin-section .content-coin .box.box-middle .arrow-2 img {
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
        height: initial;
        width: 120px
    }
}

@media only screen and (max-width:991px) and (max-width:767px) {
    .coin-section .content-coin .box.box-middle .arrow-2 img {
        width: 100px
    }
}

@media only screen and (max-width:991px) and (max-width:480px) {
    .coin-section .content-coin .box.box-middle .arrow-2 img {
        width: 120px
    }
}

@media only screen and (max-width:575px) {
    .coin-section .content-coin .box.box-middle .arrow-2 {
        height: 60px
    }
}

@media only screen and (max-width:767px) {
    .coin-section .content-coin .box.box-last .item-coin .box-left {
        width: 100%
    }
}

@media only screen and (max-width:767px) {
    .coin-section .content-coin .box.box-last .item-coin .box-left .name-coin {
        margin-bottom: 10px
    }
}

@media only screen and (max-width:480px) {
    .coin-section .content-coin .box.box-last .item-coin .box-left .name-coin {
        width: 100%;
    }
}

@media only screen and (max-width:767px) {
    .coin-section .content-coin .box.box-last .item-coin .box-right {
        width: 100%;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center
    }
}

.coin-section .content-coin .box.box-last .item-coin .box-right .list-coin span {
    text-align: center
}

.list-1 .coin-section .content-coin .box .item-coin {
    max-width: 50%;
}

.coin-section .content-coin .box .item-coin {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 30px 50px;
    border-radius: 8px
}

@media only screen and (max-width:1700px) and (min-width:1490px) {
    .coin-section .content-coin .box .item-coin {
        padding: 18px 30px;
        min-height: 400px;
    }
}

@media only screen and (max-width:1500px) and (min-width:1200px) {
    .coin-section .content-coin .box .item-coin {
        padding: 15px 20px
    }
}

@media only screen and (max-width:991px) {
    .coin-section .content-coin .box .item-coin {
        max-width: 100%;
        margin: 0;
        padding: 10px;
        text-align: center;
    }
}

.coin-section .content-coin .box .item-coin.item-center {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.coin-section .content-coin .box .item-coin .img-coin {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 20px;
    height: 150px
}

@media only screen and (max-width:1700px) and (min-width:1490px) {
    .coin-section .content-coin .box .item-coin .img-coin {
        height: 140px;
    }
}

@media only screen and (max-width:1500px) and (min-width:1200px) {
    .coin-section .content-coin .box .item-coin .img-coin {
        height: 140px;
    }
}

@media only screen and (max-width:991px) {
    .coin-section .content-coin .box .item-coin .img-coin {
        height: 80px
    }
}

.coin-section .content-coin .box .item-coin .name-coin {
    color: var(--white);
    font-size: 24px;
    font-weight: 700;
    text-transform: uppercase;
}

.coin-section .content-coin .list-item-coin.list-3 .item-coin .name-coin {
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 140px;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media only screen and (max-width:1500px) {
    .coin-section .content-coin .box .item-coin .name-coin {
        font-size: 24px
    }
}

@media only screen and (max-width:991px) {
    .coin-section .content-coin .box .item-coin .name-coin {
        font-size: 16px
    }
}

@media only screen and (max-width:575px) {
    .coin-section .content-coin .box .item-coin .name-coin {
        font-size: 14px
    }

    .coin-section .content-coin .list-item-coin.list-3 .item-coin .name-coin {
        min-height: 80px;
        margin-bottom: 0 !important;
    }
}

.coin-section .content-coin .box .item-coin .box-center {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.coin-section .content-coin .box .item-coin .box-center .img-coin {
    width: 100%
}

.coin-section .content-coin .box .item-coin .box-left {
    text-align: center
}

.coin-section .content-coin .box .item-coin .box-left .desc-coin {
    color: #ffd955;
    font-size: 15px;
    font-weight: 700
}

.coin-section .content-coin .box .item-coin .box-right {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.coin-section .content-coin .box .item-coin .box-right .list-coin span {
    display: block;
    margin-bottom: 15px;
    color: #fff;
    font-size: 16px;
    text-align: left
}

@media only screen and (max-width:1500px) {
    .coin-section .content-coin .box .item-coin .box-right .list-coin span {
        font-size: 16px;
        margin-bottom: 10px
    }
}

@media only screen and (max-width:767px) {
    .coin-section .content-coin .box .item-coin .box-right .list-coin span {
        font-size: 13px;
        margin-bottom: 10px
    }
}

.coin-section .content-coin .box .item-coin .box-right .list-coin span:last-child {
    margin-bottom: 0
}

.coin-section .content-coin .box .item-coin .box-inline {
    display: -webkit-box;
    display: -ms-flexbox;
    display: grid;
    grid-template-columns: 30% 70%;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
}

@media only screen and (max-width:991px) {
    .coin-section .content-coin .box .item-coin .box-inline {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center
    }

    .coin-section .content-coin .box .item-coin .box-inline .img-coin {
        margin-bottom: 20px
    }
}

.coin-section .content-coin .box .item-coin .box-inline .img-coin {
    margin-bottom: 0;
    margin-right: -2rem;
}

@media only screen and (max-width:991px) {
    .coin-section .content-coin .box .item-coin .box-inline .img-coin {
        margin-right: 0
    }
}

@media only screen and (max-width:480px) {
    .coin-section .content-coin .box .item-coin .box-inline .img-coin {
        width: 50%;
    }
}

.coin-section .content-coin .box-btn-main {
    margin-left: auto;
    margin-top: 50px
}

@media only screen and (max-width:1700px) and (min-width:1490px) {
    .coin-section .content-coin .box-btn-main {
        margin-top: 30px
    }
}

@media only screen and (max-width:1500px) and (min-width:1200px) {
    .coin-section .content-coin .box-btn-main {
        margin-top: 5px
    }
}

@media only screen and (max-width:991px) {
    .coin-section .content-coin .box-btn-main {
        margin: 30px auto 0
    }
}

.list-item-coin.list-vertical {
    --spacing: 15px;
    --column: 4;
    margin-bottom: calc(-1 * var(--spacing));
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    justify-content: space-between;
}

.list-item-coin.list-vertical .box-left.box-inline {
    grid-template-columns: unset !important;
}

.list-item-coin.list-vertical .item-coin {
    margin-bottom: var(--spacing);
    width: calc((100% / var(--column)) - var(--spacing));
    min-height: 340px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.list-item-coin.list-vertical .item-coin .box-inline {
    display: block !important;
}

.list-item-coin.list-vertical .item-coin .box-inline .img-coin {
    margin-right: 0 !important;
    margin-bottom: 2rem !important;
    height: 160px !important;
}

.list-item-coin.list-vertical .item-coin .box-inline .name-coin {
    font-size: 18px;
}


@media only screen and (min-width:1480px) {
    .list-item-coin.list-vertical .item-coin {
        min-height: 205px;
        margin-bottom: 30px !important;
    }
}


@media only screen and (max-width:1200px) {
    .list-item-coin.list-vertical .item-coin {
        min-height: auto;
    }
}

@media only screen and (max-width:991px) {
    .list-item-coin.list-vertical {
        width: 100%;
        --column: 4;
        flex-wrap: wrap;
    }

    .list-item-coin.list-vertical .item-coin {
        min-height: 205px;
        margin-bottom: 30px !important;
        background-size: contain;
    }

    .list-item-coin.list-vertical .item-coin .box-left .name-coin {
        width: 100% !important;
    }
}

@media only screen and (max-width:767px) {
    .list-item-coin.list-vertical {
        width: 100%;
        --column: 4;
        flex-wrap: wrap;
    }
}


@media only screen and (max-width:480px) {
    .list-item-coin.list-vertical {
        width: 100%;
        --column: 2;
        --spacing: 0px;
        flex-wrap: wrap;
    }
}

.gem-section {
    position: relative
}

.gem-section .content-coin {
    --spacing: 15px;
    --column: 2;
    margin-bottom: calc(-1 * var(--spacing));
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    justify-content: space-between;
}

@media only screen and (max-width:767px) {
    .gem-section .content-coin {
        --column: 1;
    }
}

.gem-section .content-coin .box {
    margin-bottom: var(--spacing);
    width: calc((100% / var(--column)) - var(--spacing));
}

.gem-section:before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover
}

.gem-section .content-coin .box-first .list-item-coin .item-coin {
    margin-left: auto
}

.team-section {
    background-color: #000;
    padding: 100px 80px;
    padding-top: 0;
}

.advisor-section {
    background-color: #000;
    padding: 100px 80px;
    padding-top: 0px;
}

.team-background-overlay {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0.8;
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
    height: 100%;
    width: 100%;
    inset: 0;
    position: absolute;
}

@media screen and (max-width:480px) {
    .team-section {
        padding: 50px 10px;
    }

    .advisor-section {
        padding: 50px 10px;
    }
}

.team-section .box-team-slider {
    position: relative
}

.team-section .team-slider {
    padding-bottom: 120px
}

.team-section .box-team-slider .swiper-wrapper {
    padding-bottom: 40px;
}

@media only screen and (max-width:1700px) and (min-width:1490px) {
    .team-section .team-slider {
        padding-bottom: 60px
    }
}

@media only screen and (max-width:1500px) and (min-width:1200px) {
    .team-section .team-slider {
        padding-bottom: 60px
    }
}

@media only screen and (max-width:1190px) {
    .team-section .team-slider {
        padding-bottom: 50px
    }
}

@media screen and (max-width:480px) {
    .team-section .team-slider {
        padding-bottom: 0px
    }
}

.team-section .team-slider .item-team {
    min-height: 300px;
    padding: 20px 10px;
    border-radius: 5px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.team-section .team-slider .item-team svg {
    color: var(--primary-color);
}

.team-section .team-slider .item-team .linkedin {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 99px;
    background-color: #1c1c1c;
    transition: .4s ease;
}

.team-section .team-slider .item-team .linkedin:hover {
    box-shadow: rgb(248 164 27 / 70%) 0px 2px 8px 0px;
}

@media only screen and (max-width:575px) {
    .team-section .team-slider .item-team {
        max-width: 280px;
        margin: 0 auto
    }
}

.team-section .team-slider .item-team .avatar {
    position: relative;
    margin-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px
}

.team-section .team-slider .item-team .linkedin img {
    width: 20px;
    margin-bottom: 10px;
}

@media only screen and (max-width:1500px) {
    .team-section .team-slider .item-team .avatar {
        margin-bottom: 10px;
    }
}

.team-section .team-slider .item-team .avatar img {
    position: relative;
    border-radius: 50%;
    max-width: 140px;
    max-height: 140px;
    object-fit: cover;
}

.team-section .team-slider .item-team .name {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: .2rem;
    color: var(--primary-color);
    text-transform: uppercase;
}

.team-section .team-slider .item-team .position {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 10px;
    color: #fff;
    text-transform: capitalize;
}


@media only screen and (max-width:1500px) {
    .team-section .team-slider .item-team .position {
        font-size: 14px;
    }
}

.roadmap-section {
    background-color: #000;
    padding: 100px 80px;
    position: relative;
    overflow: hidden;
}

.roadmap-background-overlay {
    background-position: top;
    background-repeat: no-repeat;
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    position: absolute;
}

.btn-launchpad {
    border-radius: 99px !important;
    transition: all .4s ease-in !important;
    color: var(--white) !important;
    font-size: 16px !important;
    background-color: var(--primary-color) !important;
    padding: .6rem 1.6rem !important;
}

.btn-launchpad:hover {
    transform: scale(1.1);
}

.box-launchpad {
    margin-top: 2rem;
    text-align: center;
}

@media only screen and (max-width:991px) {
    .roadmap-section {
        padding: 50px 30px;
    }
}

@media screen and (max-width:480px) {
    .roadmap-section {
        padding: 50px 15px;
    }
}


.roadmap-section .box-section {
    position: relative;
    height: 100%;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.roadmap-section .bg-process {
    position: relative;
    display: block;
    width: 100%
}

@media only screen and (max-width:991px) {
    .roadmap-section .bg-process {
        padding: 20px 20px 40px
    }
}

.roadmap-section .bg-process .box-process {
    position: relative;
    width: 100%;
    margin: 0 auto;
}

.roadmap-section .bg-process .box-process .list-step {
    position: relative;
}

@media only screen and (max-width: 991px) {
    .roadmap-section .bg-process .box-process {
        display: none;
    }
}

.roadmap-section .bg-process .box-process .list-step .item-step {
    position: relative;
    margin-bottom: 35px;
    padding: 10px 20px;
    border-radius: 5px;
    background-color: transparent;
    min-height: 550px;
    padding-top: 70px;
}

.roadmap-section .bg-process .box-process .list-step:before {
    position: absolute;
    content: "";
    top: 29px;
    left: 25px;
    width: 100%;
    height: 6px;
    background-color: #f2f2f2;
    margin: 0 auto;
    display: none;
}

.roadmap-section .bg-process .box-process-mobile {
    max-width: 100%;
    margin: 0 auto
}

.roadmap-section .bg-process .box-process-mobile .list-step {
    position: relative;
    padding-top: 25px
}

.roadmap-section .bg-process .box-process .list-step .item-step .icon-step {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-self: center;
    width: 44px;
    height: 44px;
    border-radius: 100%;
    position: absolute;
    top: -60px;
    left: 10px;
}

.roadmap-section .bg-process .box-process-mobile .list-step .item-step {
    position: relative;
    width: 50%;
    margin-bottom: 35px
}

.roadmap-section .bg-process .box-process-mobile .list-step .item-step.step-right {
    width: 100%;
    padding-left: 70px
}

@media only screen and (max-width:767px) {
    .roadmap-section .bg-process .box-process-mobile .list-step .item-step.step-right {
        padding-left: 60px
    }
}

.roadmap-section .bg-process .box-process-mobile .list-step:before {
    position: absolute;
    content: "";
    top: 19px;
    left: 19px;
    width: 6px;
    height: calc(100% - 82px);
    background-color: #f2f2f2;
    margin: 0 auto;
}

@media only screen and (max-width:767px) {
    .roadmap-section .bg-process .box-process-mobile .list-step:before {
        left: 12px;
        height: calc(100% - 88px)
    }
}

.roadmap-section .bg-process .box-process-mobile .list-step .item-step.step-right .info-step:before {
    left: -16px !important;
    right: initial;
    border-left: 18px solid transparent;
    border-right: 0
}

.roadmap-section .bg-process .box-process-mobile .list-step .item-step.step-right .icon-step {
    left: 0;
    right: 0;
    transition: all .4s ease;
}

.roadmap-section .bg-process .box-process-mobile .list-step .item-step .icon-step:after,
.roadmap-section .bg-process .box-process-mobile .list-step .item-step .icon-step:before,
.roadmap-section .bg-process .box-process .list-step .item-step .icon-step:after,
.roadmap-section .bg-process .box-process .list-step .item-step .icon-step:before {
    content: "";
    width: 44px;
    height: 44px;
    top: 0px;
    left: 0px;
    position: absolute;
    background: radial-gradient(50% 50% at 50% 50%, var(--pri) 0%, var(--primary-color) 100%);
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    border: 2px solid transparent;
    opacity: .6;
    -webkit-animation: button-ripple 3s infinite ease-in-out;
    -moz-animation: button-ripple 3s infinite ease-in-out;
    -ms-animation: button-ripple 3s infinite ease-in-out;
    -o-animation: button-ripple 3s infinite ease-in-out;
    animation: button-ripple 3s infinite ease-in-out;
    display: none;
}

.roadmap-section .bg-process .box-process-mobile .list-step .item-step .icon-step:after {
    -webkit-animation-delay: .3s;
    animation-delay: .3s;
}

.roadmap-section .bg-process .box-process-mobile .list-step .item-step .icon-step:before {
    -webkit-animation-delay: .9s;
    animation-delay: .9s;
}

@media only screen and (max-width:767px) {

    .roadmap-section .bg-process .box-process-mobile .list-step .item-step .icon-step:after,
    .roadmap-section .bg-process .box-process-mobile .list-step .item-step .icon-step:before {
        top: -7px;
        left: -7px;
    }
}

@-webkit-keyframes button-ripple {
    70% {
        -webkit-box-shadow: 0 0 0 15px currentColor;
        box-shadow: 0 0 0 15px currentColor;
        opacity: 0
    }

    100% {
        -webkit-box-shadow: 0 0 0 0 currentColor;
        box-shadow: 0 0 0 0 currentColor;
        opacity: 0
    }
}

@keyframes button-ripple {
    70% {
        -webkit-box-shadow: 0 0 0 15px currentColor;
        box-shadow: 0 0 0 15px currentColor;
        opacity: 0
    }

    100% {
        -webkit-box-shadow: 0 0 0 0 currentColor;
        box-shadow: 0 0 0 0 currentColor;
        opacity: 0
    }
}

.roadmap-section .bg-process .box-process-mobile .list-step .item-step:last-child {
    width: 100%
}

.roadmap-section .bg-process .box-process-mobile .list-step .item-step:last-child .alpha {
    margin-left: 0 !important
}

.roadmap-section .bg-process .box-process-mobile .list-step .item-step .icon-step {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-self: center;
    width: 44px;
    height: 44px;
    border-radius: 100%;
    position: absolute;
    top: -22px;
    left: 0
}

@media only screen and (max-width:767px) {
    .roadmap-section .bg-process .box-process-mobile .list-step .item-step .icon-step {
        width: 30px;
        height: 30px;
        top: -15px
    }
}

.roadmap-section .bg-process .box-process-mobile .list-step .item-step .icon-step span {
    font-size: 32px;
    margin: 0 auto
}

.roadmap-section .bg-process .box-process-mobile .list-step .item-step .icon-step span:before {
    color: #fff
}

.roadmap-section .bg-process .box-process-mobile .list-step .item-step .info-step {
    position: relative;
    background-color: #151515;
    padding: 20px 20px 15px;
    cursor: pointer
}

.item-step ul {
    list-style: decimal;
}

.item-step .info {
    padding-left: 15px;
    position: relative;
    font-size: 16px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    color: var(--white);
}

.roadmap-section .bg-process .box-process-mobile .list-step .item-step .info-step:before {
    position: absolute;
    content: "";
    top: 0;
    left: -16px;
    right: initial;
    border-left: 18px solid transparent;
    border-right: 0;
    border-top: 18px solid #151515;
}

.roadmap-section .bg-process .box-process-mobile .list-step .item-step .info-step .time,
.roadmap-section .bg-process .box-process .list-step .item-step .info-step .time {
    font-weight: 700;
    font-size: 22px;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

@media only screen and (max-width:767px) {
    .roadmap-section .bg-process .box-process-mobile .list-step .item-step .info-step .time {
        font-size: 20px;
        color: #fff;
    }
}

.roadmap-section .bg-process .box-process-mobile .list-step .item-step .info-step .alpha,
.roadmap-section .bg-process .box-process .list-step .item-step .info-step .alpha {
    color: #fff;
    font-weight: 700;
    font-size: 20px;
    margin-left: 15px
}

@media only screen and (max-width:575px) {
    .roadmap-section .bg-process .box-process-mobile .list-step .item-step .info-step .alpha {
        font-size: 14px
    }
}

.roadmap-section .bg-process .box-process-mobile .list-step .item-step .info-step .info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 2px;
    font-size: 15px;
}

.roadmap-section .bg-process .box-process-mobile .list-step .item-step .info-step .info img,
.roadmap-section .bg-process .box-process .list-step .item-step .info-step .info img {
    width: 18px;
    height: auto;
    margin-right: 5px;
}

.partner-section {
    background-color: #000;
    padding: 100px 80px;
    z-index: 1;
}

@media only screen and (max-width:991px) {
    .partner-section {
        padding: 50px 30px;
    }
}

@media only screen and (max-width:767px) {
    .partner-section {
        padding: 50px 15px;
    }
}

.partner-section .box-partner {
    margin-bottom: 50px;
    z-index: 1;
}

.partner-background-overlay {
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: .4;
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
    z-index: -1;
}

.partner-section .item-develop {
    width: 150px;
    padding: 0 12px;
    margin: 0 auto 30px
}

@media only screen and (max-width:767px) {
    .partner-section .item-develop {
        width: 33.333%
    }
}

.partner-section .list-partner {
    --spacing: 20px;
    --column: 6;
    margin-bottom: calc(-1 * var(--spacing));
    margin-left: calc(-1 * var(--spacing));
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    justify-content: center;
}

.partner-section .list-partner .item-partner {
    padding: 0 12px;
    margin-left: var(--spacing);
    margin-bottom: var(--spacing);
    width: calc((100% / var(--column)) - var(--spacing));
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    border: 1px solid #f8a31b88;
    box-shadow: 0 0 10px #f8a31b88;
    transition: .4s all;
}

.partner-section .list-partner .item-partner img {
    width: 170px;
    height: 100px;
    object-fit: contain;
}

@media only screen and (max-width:767px) {
    .partner-section .list-partner {
        --column: 3;
    }
}

@media only screen and (max-width:480px) {
    .partner-section .list-partner {
        --column: 2;
    }

    .partner-section .list-partner .item-partner img {
        width: auto;
        height: 100px;
    }
}

.talent-modal .modal-title {
    color: var(--dark)
}

.talent-modal .modal-title span {
    color: var(--primary)
}

.talent-modal .modal-body {
    padding: 30px 15px
}

.talent-modal a {
    color: var(--primary)
}

.gameplay-modal .modal-dialog {
    max-width: 900px
}

.gameplay-modal .modal-body {
    padding: 10px
}

.gameplay-modal .close {
    position: absolute;
    top: -20px;
    right: -15px;
    width: 34px;
    height: 34px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    color: #fff;
    z-index: 2;
    opacity: 1;
    font-size: 40px;
    background-color: #2f2477;
    border-radius: 100%
}

@media only screen and (max-width:767px) {
    .gameplay-modal .close {
        right: -10px
    }
}

.gameplay-modal .box-iframe {
    position: relative;
    padding-bottom: 56.25%
}

.gameplay-modal .box-iframe iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%
}

.notice-modal .modal-dialog {
    max-width: 900px
}

.notice-modal .modal-body {
    padding: 10px
}

.notice-modal .modal-body iframe {
    width: 100%;
    height: 70vh;
    border: 0
}

.notice-modal .modal-header #noticeModalTitle {
    color: var(--dark);
    margin: auto
}

.notice-modal .modal-footer button {
    margin: auto
}

.notice-modal .modal-body {
    color: var(--dark);
    font-size: 10px;
    font-style: normal;
    font-family: Arial, Helvetica, sans-serif
}

.scroll-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 999;

}

.scroll-top-wrap {
    width: 40px;
    height: 40px;
    border-radius: 99px;
    background-color: transparent;
    transition: all .4s ease;
    cursor: pointer;
    box-shadow: 0 0 10px var(--primary-color);
    display: flex;
    align-items: center;
    justify-content: center;
}

.scroll-top-wrap svg {
    color: #fff;
    font-size: 20px;
}

.scroll-top-wrap:hover {
    transform: translateY(-10px);
}

.video-lightbox>div {
    z-index: 999;
}

.road-map-item-wrap {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 30px;
}

.road-map-item-wrap .info-step:first-child {
    transform: translateY(100px) !important;
}

.road-map-item-wrap .info-step:nth-child(2) {
    transform: translateY(50px) !important;
}

.road-map-item-wrap .info-step:last-child {
    transform: translateY(-50px) !important;
}

.roadmap-desk .swiper-container {
    overflow-y: inherit !important;
}

.swiper-container-horizontal>.swiper-pagination-bullets,
.swiper-pagination-custom,
.swiper-pagination-fraction {
    bottom: 0;
}