.layout-wrapper .header {
    padding: 0px 30px;
    min-height: 60px;
    background-color: #000;
    box-shadow: 0 0 5px var(--primary-color);
}

.layout-wrapper .header .menu-main ul {
    margin-top: 0px;
}

.layout-wrapper .header .menu-main ul li.button-wallet .btn-main:hover {
    background-color: #c37e0f;
    border-color: #c37e0f;
}

.market-place-button {
    border-radius: 5px;
}

@media only screen and (max-width:991px) {

    .layout-wrapper .header .menu-main ul li.button-wallet .btn-main,
    .layout-wrapper .header .menu-main ul li.button-wallet .btn-connect-wallet {
        color: var(--dark);
    }

    .y-mobile-menu ul li a.market-place-button {
        background-color: transparent;
        justify-content: flex-start;
        text-transform: capitalize;
    }

    .market-place-button{
        border: 0px !important;
    }
}

.layout-wrapper {
    background-color: #000;
}

.layout-main {
    min-height: 100vh;
    /* padding-top: 6.25rem; */
    padding-bottom: 6.25rem;
    box-sizing: border-box;
}

.box-img-flex{ 
    cursor: pointer;
}