.btn-demo-wrap {
    min-height: 120px;
    width: 500px;
    background-repeat: no-repeat;
    background-size: contain;
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn{
    border-radius: 99px !important;
}

p,
span,
.desc-game,
.name,
h3,
label{
    color: var(--white);
}

@media only screen and (max-width:991px) {
    .btn-demo-wrap {
        background-position: center;
        min-height: 85px;
        width: 100%;
    }
}

.btn-demo-wrap a {
    text-transform: uppercase;
    font-size: 24px;
    color: var(--primary-color);
    letter-spacing: 5px;
    text-shadow: 0px 4px 4px rgba(0, 255, 255, 0.35);
}

.about_us_left .box-title-main {
    text-align: center;
}

.about_us_left .box-title-main span {
    display: block;
    margin-bottom: 10px;
}

.about_us_left {
    text-align: center;
}

.btn-car {
    display: flex;
    align-items: start;
    justify-content: center;
    gap: 1rem;
}

.btn-car .btn-car-action {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 30px;
    transition: .3s ease-out;
}

.btn-car .btn-car-action img {
    width: 100%;
    object-fit: contain;
}

.btn-car .btn-car-action .btn-car-content {
    position: absolute;
    left: 2rem;
    top: 2rem;
}

.btn-car .btn-car-action .btn-car-content span {
    font-size: 36px;
    color: var(--primary-color);
    font-weight: 600;
    display: block;
}

@media only screen and (max-width:991px) {
    .btn-car {
        padding: 0 20px;
        flex-direction: column;
    }

    .btn-car .btn-car-action .btn-car-content{
        text-align: center;
        top: 0;
        left: 1rem;
    }

    .btn-car .btn-car-action .btn-car-content span {
        font-size: 24px;
    }

    .btn-car .btn-car-action{
        justify-content: center;
    }
}

.btn-car .btn-car-action .btn-car-content img {
    width: 100px;
}

.btn-car .btn-car-action:hover {
    transform: translateX(10px);
}

.box-btn-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    gap: 30px;
}

.box-btn-wrap .btn-icon {
    padding: 10px 20px;
    position: relative;
    color: var(--dark-color);
    background-color: var(--primary-color);
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    z-index: 1;
    border-radius: 99px;
    font-size: 14px;
    transition: .4s ease;
    border: 1px solid var(--primary-color);
}

.box-btn-wrap .btn-icon:hover{
    box-shadow: 0 0 5px var(--primary-color);
    background-color: transparent;
}


@media only screen and (max-width:991px) {
    .box-btn-wrap {
        justify-content: center;
    }
}

.gameplay-section,
.coin-section {
    z-index: 1;
    overflow: hidden;
}

.gameplay_bg {
    position: absolute;
    top: 22%;
    left: 0;
    right: 0;
    height: 250px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: -1;
}

.bg-icon-game {
    position: absolute;
    width: 100%;
    top: 10px;
    left: 0;
    right: 0;
    height: 80px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 1;
    opacity: 0.2;
}

.gameplay-section .gameplay-slider .item .box-content .box-right .icon-game {
    position: relative;
}

.gameplay-section .gameplay-slider .item .box-content .box-right .icon-game img {
    z-index: 10;
}

.coin-section .box-title-main {
    width: 50%;
    margin: 0 auto;
    margin-bottom: 50px;
}

@media only screen and (max-width:991px) {
    .coin-section .box-title-main {
        width: 100%;
    }
}

.box-title-main .desc-main {
    line-height: 1.5em;
}

.first-item-coin {
    background-size: auto;
    min-height: 300px !important;
    align-items: center;
    background-repeat: no-repeat !important;
    background-position: center !important;
}

.first-item-coin .name-coin {
    color: var(--primary-color) !important;
}

@media only screen and (max-width:991px) {
    .first-item-coin {
        min-height: 200px !important;
        max-width: 100%;
        background-size: contain;
    }
}


.roadmap-section .bg-process .box-process .list-step .item-step .info-step .time {
    height: 85px;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--white);
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
}

.team-section .team-slider .item-team .avatar {
    width: 200px;
    height: 200px;
    display: flex;
    align-items: center;
    background-size: contain;
    justify-content: center;
}

.advisor-background-overlay {
    position: absolute;
    inset: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.item-advisor {
    text-align: center;
}

.item-advisor img {
    width: 125px;
    height: 125px;
    object-fit: cover;
    border-radius: 50%;
}

.advisor-slider {
    display: grid;
    grid-template-columns: auto auto auto auto;
    gap: 30px;
    margin-top: -80px;
}

@media only screen and (max-width:991px) {
    .advisor-slider {
        display: grid;
        grid-template-columns: none;
    }
}

.web3modal-modal-lightbox{
    z-index: 100 !important;
}

.box-advisor-wrap {
    padding-top: 3rem;
}

.item-advisor .avatar {
    margin: 0 auto;
    width: 130px;
    height: 130px;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    transform: translateY(65px);
}

.item-advisor-content {
    background-repeat: no-repeat;
    width: 100%;
    background-size: auto;
    min-height: 200px;
    background-position: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    z-index: -1;
    background-color: #151515;
    border-radius: 5px;
    border: 1px solid #f8a31b88;
    box-shadow: 0 0 10px #f8a31b88;
}

.item-advisor-content .name {
    font-size: 24px;
    font-weight: 600;
    color: var(--primary-color);
}

.coin_bg {
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    height: 350px;
    position: absolute;
    top: 19%;
    left: -10%;
    right: -10%;
    z-index: -1;
}

.coin_line {
    height: 100px;
    background-size: 100%;
    position: absolute;
    z-index: -1;
    width: 100%;
    left: 0;
    right: 0;
    bottom: 0;
}

.item-advisor-content .position {
    max-width: 200px;
    color: var(--white);
}

.item-advisor-content .name {
    margin-bottom: 10px;
    margin-top: 30px;
}

.btn-smartConstractWrapper {
    position: fixed;
    bottom: 30px;
    left: 20px;
    z-index: 999;
}

.btn-smartConstract {
    text-transform: uppercase;
    padding: 0 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: .3rem;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 40px;
    border-radius: 99px;
    transition: all .4s ease-in;
    color: var(--white);
    font-size: 14px;
    background-color: transparent;
    border: 1px solid var(--primary-color);
    box-shadow: 0 0 10px var(--primary-color);
}

.btn-smartConstract:hover {
    background-color: transparent !important;
    color: var(--primary-color);
    border: 1px solid var(--primary-color);
}

.smartConstractWrapper {
    position: fixed;
    inset: 0;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #110c287d;
    border-radius: 10px;
    -webkit-backdrop-filter: blur(12px);
    backdrop-filter: blur(12px);
}

.smartConstractItem-content {
    background: #ffffff24;
    padding: .5rem;
    border-radius: 5px;
    width: 100%;
}

.smartConstractItem-content span:last-child{
    word-wrap: break-word;
}

.smartConstractItem {
    width: 600px;
    height: 300px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    text-align: center;
    align-items: center;
    justify-content: center;
    gap: 8px;
    background: #000;
    border-radius: 5px;
    position: relative;
    border: 1px solid var(--primary-color);
    box-shadow: 0 0 10px var(--primary-color);
    padding: 10px;
}

@media only screen and (max-width:767px) {
    .smartConstractItem {
        width: 90%;
        height: 300px;
    }

    .smartConstractItem-content {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: center;
        font-size: 13px;
        margin-bottom: 15px;
    }
    
    .smartConstractItem-content * {
        margin: 0 !important;
    }
}

.smartConstractItem-content .text-primary {
    margin-bottom: 10px;
    display: block;
}

.smartConstractItem-content .copy{
    margin-left: 10px;
    color: var(--primary-color);
    cursor: pointer;
}

.smartConstractItem img {
    width: 100px;
}

.smartConstractItem>span {
    font-size: 20px;
}

.close-Constract {
    position: absolute;
    right: -15px;
    top: -15px;
    color: #fff;
    width: 32px;
    height: 32px;
    background-color: #de9219;
    border-radius: 30px;
    cursor: pointer;
}

.video-lightbox>div{
    background-color: #110c287d !important;
    -webkit-backdrop-filter: blur(32px);
    backdrop-filter: blur(32px);
}