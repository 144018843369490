.Affiliates:parent{
    background-color: red;
}

.Affiliates-formWrapper {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 90px 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Affiliates-form {
    width: 600px;
    margin: 0 auto;
    padding: 20px;
}

.Affiliates-form .Logo {
    margin: 0 auto;
    width: 50%;
}

.Affiliates-form .title {
    font-size: 24px;
    text-align: center;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 20px;
}

.Affiliates-form input{
    color: #000 !important;
}

.Affiliates-form label{
    font-size: 14px;
}

.Affiliates-link a{
    display: block;
    text-align: center;
    color: var(--primary-color);
}

.Affiliates-boxWrap{
    padding: 50px;
    background-color: #0C0E34;
}

.Affiliates-card {
    padding: 15px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    min-height: 200px;
    border-radius: 8px;
    transition: .2s ease-in;
}

.Affiliates-card .card-icon {
    text-align: center;
}

.Affiliates-card .card-icon svg{
    color: #fff;
}

.Affiliates-card span {
    color: #fff;
}

.Affiliates-card .card-title {
    font-size: 16px;
    display: block;
    text-align: center;
    font-weight: 600;
    color: var(--primary-color);
}

.Affiliates-card .card-content {
    font-size: 13px;
    text-align: center;
    display: block;
}

.Affiliates-card:hover {
    background-color: var(--primary-color);
    transform: scale(1.1);
}

.Affiliates-card:hover .card-title{
    color: #fff !important;
}

.Affiliates-benefitTitle {
    display: block;
    text-align: center;
    color: #fff;
    font-size: 28px;
}

.Affiliates-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    margin-top: 40px;
    flex-direction: column;
}

.Affiliates-containerLeft {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 20px;
}

.Affiliates-benefit {
    padding: 100px 0px;
    background-color: #0C0E34;
}

.Affiliates-containerLeft-top {
    height: 72px;
    padding: 4px 12px;
    position: relative;
    font-size: 18px;
    flex: 0 0 260px;
}

.Affiliates-containerLeft-top span{
    color: #fff;
    display: block;
}

.Affiliates-containerLeft-top span:last-child{
    font-size: 16px;
}

.Affiliates-containerLeft-top:before {
    border-radius: 1.5px;
    content: "";
    height: 112px;
    left: 0;
    position: absolute;
    width: 3px;
    background-color: var(--primary-color);
}

.Affiliates-circle {
    align-items: center;
    background-clip: padding-box;
    box-sizing: content-box;
    border: 7px solid #fff;
    border-radius: 50%;
    color: #fff;
    display: flex;
    font-size: 92px;
    justify-content: center;
    height: 200px;
    width: 200px;
    flex: 0 0 200px;
    padding: 10px;
}

.Affiliates-circle--brand {
    background-color: var(--primary-color);
}

.Affiliates-circle--blue {
    background-color: #07254f;
}

.Affiliates-left-bottom {
    font-size: 18px;
    align-self: flex-end;
    position: relative;
    flex: 0 0 260px;
    padding: 4px 12px;
}

.Affiliates-left-bottom span{
    color: #fff;
    display: block;
}

.Affiliates-left-bottom span:last-child{
    font-size: 16px;
}

.Affiliates-left-bottom:before {
    border-radius: 1.5px;
    content: "";
    height: 112px;
    right: 0;
    position: absolute;
    width: 3px;
    background-color: var(--primary-color);
}

.Affiliates-partner{
    padding: 50px;
    background-color: #0C0E34;
}

.Affiliates-partnerTitle{
    display: block;
    text-align: center;
    color: #fff;
    font-size: 28px;
    margin-bottom: 60px;
}

.Affiliates-partnerBox{
    padding: 25px 15px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    min-height: 200px;
    border-radius: 8px;
    transition: .2s ease-in;
    border: 3px solid #f0f0f0;
    display: flex;
    align-items: center;
    position: relative;
}

.Affiliates-partnerBox:hover{
    border-color: #f8a41a;
}

.Affiliates-partnerBox-icon {
    position: absolute;
    top: -40px;
    background-color: #f8a41a;
    padding: 10px;
    border-radius: 50%;
}

.Affiliates-partnerBox-icon svg {
    color: #fff;
}

.Affiliates-partnerBox-text span {
    color: #fff;
    display: block;
    z-index: 10;
    text-align: center;
}

.Affiliates-partnerBox-text__Title {
    font-size: 20px;
    margin-bottom: 10px;
    text-align: center;
    color: #f8a41a !important;
}

@media screen and (max-width:1024px) {
    .Affiliates-containerLeft {
        flex-wrap: wrap;
    }
    
    .Affiliates-containerLeft-top {
        flex: 0 0 100%;
    }
    
    .Affiliates-left-bottom {
        flex: 0 0 100%;
        text-align: right;
    }

    .Affiliates-partnerBox {
        margin-bottom: 80px;
    }
}

@media screen and (max-width:767px) {
    .Affiliates-form{
        width: 100%;
    }

    .Affiliates-formWrapper {
        padding: 10px;
    }
    
    .Affiliates-card {
        margin-bottom: 30px;
    }

    .Affiliates-formWrapper {
        padding: 10px;
    }
    
    .Affiliates-card {
        margin-bottom: 30px;
    }
    
    .Affiliates-containerLeft {
        flex-direction: column;
        align-items: center;
    }
    
    .Affiliates-containerLeft-top {
        flex: auto;
        margin-bottom: 40px;
    }
    
    .Affiliates-benefit {
        padding: 0;
    }
    
    .Affiliates-benefitTitle {
        font-size: 24px;
    }
    
    .Affiliates-partner {
        padding: 0 20px;
    }
    
    .Affiliates-partnerBox {
        margin-bottom: 80px;
    }
    
    .Affiliates-boxWrap {
        padding: 50px 20px;
    }
}