body .header {
    padding: 5px 30px;
}

@media only screen and (max-width:991px) {
    body .header {
        padding: 10px 30px;
    }
}

@media only screen and (max-width:575px) {
    body .header {
        padding: 5px 15px;
        background-color: #000;
    }
}

body .header .logo-header .box-img-flex,
body .header .logo-header .gameplay-section .gameplay-slider .item .box-content .box-right .icon-game,
.gameplay-section .gameplay-slider .item .box-content .box-right body .header .logo-header .icon-game {
    height: 50px;
}

body.fixed .header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

@media only screen and (max-width:1500px) and (min-width:1200px) {
    body.fixed .box-section {
        padding-top: 30px;
    }
}

.loader-wrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #242f3f;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    z-index: 99999;
}

.loader-wrapper .content-loader {
    text-align: center;
}

.loader-wrapper .content-loader .box-img-flex,
.loader-wrapper .content-loader .gameplay-section .gameplay-slider .item .box-content .box-right .icon-game,
.gameplay-section .gameplay-slider .item .box-content .box-right .loader-wrapper .content-loader .icon-game {
    height: 120px;
    margin-bottom: 10px;
}

@media only screen and (max-width:767px) {
    .loader-wrapper .content-loader .box-img-flex,
    .loader-wrapper .content-loader .gameplay-section .gameplay-slider .item .box-content .box-right .icon-game,
    .gameplay-section .gameplay-slider .item .box-content .box-right .loader-wrapper .content-loader .icon-game {
        height: 100px;
    }
}


.fp-viewing-about .header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    max-width: 1920px;
    margin: 0 auto;
    padding: 15px 50px;
    background-color: transparent;
}

.is-sticky{
    background-color: #000;
    box-shadow: 0 0 5px var(--primary-color);
}

@media only screen and (max-width:1500px) {
    .header {
        padding: 10px 30px;
    }
}

@media only screen and (max-width:991px) {
    .header {
        position: fixed;
        padding: 9px 20px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex
    }
}

.header .logo-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

@media only screen and (max-width:991px) {
    .header .logo-header {
        width: 100px;
    }
}

@media only screen and (max-width:480px) {
    .header .logo-header {
        width: 200px;
    }
}


@media only screen and (max-width:1500px) {

    .header .logo-header .box-img-flex,
    .header .logo-header .gameplay-section .gameplay-slider .item .box-content .box-right .icon-game,
    .gameplay-section .gameplay-slider .item .box-content .box-right .header .logo-header .icon-game {
        height: 60px
    }
}

@media only screen and (max-width:991px) {
    .header .logo-header .box-img-flex,
    .header .logo-header .gameplay-section .gameplay-slider .item .box-content .box-right .icon-game,
    .gameplay-section .gameplay-slider .item .box-content .box-right .header .logo-header .icon-game {
        height: 52px
    }
}

.header .menu-main {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end
}

.header .menu-main ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 0;
}

.header .menu-main ul li {
    padding: 8px 10px;
}

.header .menu-main ul li.button-wallet {
    display: block;
}

@media only screen and (max-width:991px) {
    .header .menu-main ul li {
        display: none;
        padding: 0 10px
    }
}

@media only screen and (max-width:767px) {
    .header .menu-main ul li.button-wallet {
        display: none;
    }
}

.header .menu-main ul li.button-wallet .btn-main,
.header .menu-main ul li.button-wallet .btn-connect-wallet{
    text-transform: uppercase;
    padding: 0 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 40px;
    border: 1px solid var(--primary-color);
    border-radius: 99px;
    transition: all .2s;
    cursor: pointer;
    border: 1px solid var(--primary-color);
}

.header .menu-main ul li.button-wallet .btn-main:hover{
    background-color: transparent;
    box-shadow: 0 0 5px var(--primary-color);
}

.header .menu-main ul li.button-wallet span{
    font-size: 14px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: .3rem;
}

.btn-main,
.market-place-button{
    background-color: var(--primary-color);
    color: var(--primary-color);
}

.header .menu-main ul li.button-wallet .btn-connect-wallet:hover {
    background-color: var(--primary-color);
    color: var(--white);
}

@media only screen and (max-width:991px) {
    .header .menu-main ul li.button-wallet .btn-main,
    .header .menu-main ul li.button-wallet .btn-connect-wallet {
        color: var(--dark);
    }
}

.header .menu-main ul li.button-wallet .btn-connect-wallet{
    background-color: transparent;
}

.header .menu-main ul li.button-wallet .btn-main::after {
    border-top: 12px solid transparent;
    border-bottom: 32px solid transparent
}

.header .menu-main ul li.button-wallet .btn-main:hover {
    color: var(--white)
}

.header .menu-main ul li a {
    display: block;
    text-align: center;
    font-size: 14px;
    color: var(--white);
    padding: 5px 15px;
    font-weight: 600;
    transition: .4s ease;
}

.header .menu-main ul li:hover a span{
    color: var(--primary-color);
}

.header .menu-main ul li a span{
    transition: .4s ease;
}

@media only screen and (max-width:991px) {
    .header .menu-main ul li a {
        padding: 3px 15px;
        font-size: 14px;
    }
}

.header .menu-main ul li a span {
    display: block;
}

.fp-viewing-home .header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.btn-menu-mobile {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 34px;
    position: relative;
    display: none;
    margin-left: 20px
}

.btn-menu-mobile svg{
    color: #fff;
    width: 24px;
    height: 24px;
}

@media only screen and (max-width:991px) {
    .btn-menu-mobile {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex
    }
}

.btn-menu-mobile span {
    display: block;
    width: 100%;
    height: 3px;
    background-color: var(--white)
}

.btn-menu-mobile span:not(:last-child) {
    margin-bottom: 6px
}

.y-mobile-menu {
    position: fixed;
    top: 0;
    bottom: 0;
    right: -85%;
    width: 85%;
    max-width: 400px;
    background-color: #151515;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    font-size: 14px;
    -webkit-transition: .2s;
    transition: .2s;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 999
}

.y-mobile-menu.show {
    right: 0
}

.y-mobile-menu .m-menu__title {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 12px 20px;
    color: rgba(255, 255, 255, .5)
}

.y-mobile-menu .m-menu__title .title {
    font-size: 14px;
    margin: 0;
    text-align: center;
    width: 100%
}

.y-mobile-menu .m-menu__title .m-menu-close {
    padding: 0;
    font-size: 40px;
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 22px;
    text-decoration: none;
    color: var(--white);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    top: 8px;
    right: 8px;
    cursor: pointer;
    z-index: 1038;
}

.y-mobile-menu ul {
    margin: 0;
    padding-left: 0
}

.y-mobile-menu ul li {
    position: relative;
    list-style-type: none
}

.y-mobile-menu ul li .box-btn-main {
    text-align: left;
    padding: 11px 20px;
}

.y-mobile-menu ul li .box-btn-main a{
    display: flex;
}

.y-mobile-menu ul li a {
    color: var(--white);
    text-decoration: none;
    display: block;
    padding: 11px 20px !important;
    -webkit-transition: .2s;
    transition: .2s;
    line-height: 1.3;
    border-radius: 5px;
}

.y-mobile-menu ul li.active>a {
    color: #00b140
}

.y-mobile-menu ul li.has-submenu a {
    padding-right: 35px
}

.y-mobile-menu li>a:before {
    content: '';
    border-bottom: 1px solid rgba(255, 255, 255, .8);
    display: block;
    position: absolute;
    left: 20px;
    right: 0;
    bottom: 0
}

.has-submenu>a {
    position: relative
}

.has-submenu>.btn-toggle-sub {
    width: 40px;
    height: 42px;
    max-height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    color: var(--dark);
    z-index: 2
}

.has-submenu>.btn-toggle-sub:before {
    content: "+";
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-transition: .2s;
    transition: .2s;
    font-size: 18px
}

.has-submenu.opened>.btn-toggle-sub:before {
    content: "-"
}

.has-submenu .sub-menu {
    padding-left: 20px;
    display: none
}

.has-submenu .sub-menu li a {
    font-size: 14px
}

.overlay-menu.active {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .7);
    -webkit-transition: .5s;
    transition: .5s;
    z-index: 888
}

.account {
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    background-color: #ebebeb;
    border-radius: 99px;
    height: 40px;
    padding: 0px 10px;
}

.account p{
    color: #000;
    margin-bottom: 0;
}

.account p span{
    color: var(--primary-color);
}

.account-box {
    z-index: 20;
    display: none;
    position: absolute;
    top: 100%;
    right: 0px;
    width: 360px;
    padding: 2px;
}

.account-box.show {
    display: block;
}

.account-box__inner {
    padding: 17px 20px;
    background-color: #fff;
    box-shadow: 0 0 5px var(--primary-color);
    border-radius: 12px;
    border: 1px solid var(--primary-color);
}

.account-box__inner h3 {
    font-size: 20px;
    color: #333132;
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid #ccc;
}

.account-box__inner>p {
    font-size: 14px;
    margin-bottom: 16px;
    color: #333132;
}

.account-row {
    width: 100%;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
}

.account-row img {
    width: 50px;
    margin-right: 12px;
}

.account>img {
    width: 30px;
    border-radius: 50%;
    margin-left: 12px;
}

.account__name h5 {
    font-size: 14px;
    color: #9e9e9e;
    font-weight: 300;
    margin-bottom: 4px;
}

.account .links {
    padding-top: 15px;
    border-top: 1px solid #ccc;
}

.account .links a{
    display: flex !important;
    align-items: center;

}

.account .links a>p {
    font-size: 16px;
    color: #333132;
    margin-left: 16px;
    display: flex;
}

.account .links a>p>span {
    color: #2979ff;
    margin-left: 10px;
}

.account .links a:hover {
    background-color: #f2a42b;
    border-radius: 99px;
}

.y-mobile-menu ul li.account-wrapper{
    padding: 11px 20px;
}

@media screen and (max-width:991px) {
    .account{
        justify-content: flex-start;
        background-color: transparent;
        padding-left: 20px;
    }

    .account-wrapper {
        display: block !important;
    }
    
    .header .menu-main ul {
        margin-top: 0;
    }

    .account-box{
        width: 300px;
    }
}