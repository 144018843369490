.reacerBox-title {
    position: relative;
    z-index: 1;
    padding: 15px 20px;
    min-width: 25%;
    text-align: center;
    font-weight: 600;
    
}

.reacerBox-title:before {
    content: "";
    background-color: var(--primary-color);
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
    right: 0;
    margin: 0 auto;
    opacity: 1;
    clip-path: polygon(15% 0, 100% 0%, 100% 100%, 0% 100%);
}

.RacerBoxDetail {
    border-top-right-radius: 0px !important;
    display: grid;
    grid-template-columns: auto auto;
    border-radius: 10px;
    background-color: #000;
    border: 1px solid #f8a31b88;
    box-shadow: 0 0 10px #f8a31b88;
}

.RacerBoxDetailLeft {
    /* background: linear-gradient(0deg, rgb(57 207 244 / 40%) -133.98%, rgba(57, 207, 244, 0) 124.74%); */
    padding: 40px;
    text-align: center;
    border-right: 1px solid #f8a31b88;
}

.RacerBoxDetailLeft .box-img img {
    width: 75%;
}

.RacerBoxDetailLeft .box-img {
    text-align: center;
}

.RacerBoxDetailLeft .box-remaining {
    text-align: center;
    color: #fff;
    margin-top: 20px;
}

.RacerBoxDetailLeft .box-remaining span {
    color: var(--primary-color);
    font-weight: 600;
}

.RacerBoxDetailLeft .box-price {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    font-size: 16px !important;
    margin: 10px 0;
}

.RacerBoxDetailLeft .box-price h3 {
    margin-bottom: 0;
}

.RacerBoxDetailLeft .box-price h3 {
    font-size: 24px;
}

.RacerBoxDetailLeft .box-quantily {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    background-color: #191919;
    border-radius: 99px;
    padding: 10px;
}

.RacerBoxDetailLeft .box-quantily button {
    background-color: var(--primary-color);
    transition: .4s ease;
}

.RacerBoxDetailLeft .box-quantily button:hover {
    background-color: #bf7d10;
}

.RacerBoxDetailLeft .box-quantilyNumber {
    min-width: 100px;
    text-align: center;
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
}

.btn-buyRacerBox {
    position: relative;
    z-index: 1;
    width: 100%;
    background-color: var(--primary-color);
    padding: 10px 20px;
    margin-top: 20px;
    transition: .4s ease;
}

.btn-buyRacerBox:hover {
    background-color: transparent;
    box-shadow: 0 0 5px var(--primary-color);
    color: var(--white);
}

.btn-buyRacerBox:hover:before {
    background-color: #bf7d10;
}

.RacerBoxDetailRight {
    padding: 40px;
}

.RacerBox-rate {
    border-radius: 5px;
    overflow: hidden;
}

.RacerBox-title {
    font-family: 'Poppins', sans-serif;
    padding: 10px;
    text-align: center;
    background-color: var(--primary-color);
    font-weight: 600;
}

.RacerBox-rate ul {
    padding: 10px 20px;
    background-color: #191919;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0;
}

.RacerBox-rate ul li {
    text-align: center;
}

.RacerBox-rate ul li span {
    margin-bottom: 5px;
    display: block;
}

.RacerBox-rate ul li h5 {
    font-weight: 600;
}

.RacerBox-available {
    margin-top: 20px;
    border-radius: 5px;
    overflow: hidden;
}

.RacerBox-wrap {
    padding: 10px 20px;
    background-color: #191919;
    display: grid;
    grid-template-columns: auto auto auto auto auto auto;
    gap: 10px;
    max-height: 220px;
    overflow-y: scroll;
}

.RacerBox-car {
    position: relative;
    z-index: 1;
}

.RacerBox-wrap::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #F5F5F5;
}

.RacerBox-wrap::-webkit-scrollbar {
    width: 5px;
    background-color: #F5F5F5;
}

.RacerBox-wrap::-webkit-scrollbar-thumb {
    background-color: #F90;
    background-image: -webkit-linear-gradient(45deg,
            rgba(255, 255, 255, .2) 25%,
            transparent 25%,
            transparent 50%,
            rgba(255, 255, 255, .2) 50%,
            rgba(255, 255, 255, .2) 75%,
            transparent 75%,
            transparent)
}

.popUp_Checkout{
    width: 420px;
}

.popUp_Checkout .price {
    padding: 10px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #766e613b;
    border-radius: 5px;
    font-weight: 600;
}

.text-checkOut {
    width: 100%;
}

.text-checkOut .d-flex {
    margin-bottom: 8px;
}

.btn-wrapCheckOut{
    width: 100%;
}

.btn-wrapCheckOut .btn{
    width: 45%;
}

.btn-cancel {
    color: #ffff;
    font-weight: 600;
    background-color: #191919;
}

.btn-cancel:hover{
    color: var(--primary-color);
}

.btn-confirmCheckOut {
    background-color: var(--primary-color);
    font-weight: 600;
    color: #fff;
}

.boxDetail-threeCol {
    display: grid;
    align-items: center;
    justify-content: space-between;
    grid-template-columns: 30% 30% 30%;
}

.boxDetail-threeCol .box-text {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;
    border-radius: 5px;
    overflow: hidden;
}

.boxDetail-threeCol .box-text span {
    padding: 10px;
    text-align: center;
    background-color: var(--primary-color);
    font-weight: 600;
    font-size: 12px;
    display: block;
    width: 100%;
}

.boxDetail-threeCol .box-text h3 {
    font-size: 18px;
    padding: 10px 0;
    margin-bottom: 0;
    font-weight: 600;
    display: block;
    width: 100%;
    text-align: center;
    background-color: #191919;
}

.text-primary{
    color: var(--primary-color) !important;
}

.state1 {
    display: block;
    margin-top: 20px;
    color: var(--primary-color);
    border: 1px solid;
    width: 100%;
    padding: 5px;
    border-radius: 5px;
    background-color: #191919;
}

.state1 h3 {
    margin-bottom: 0;
    margin-top: 5px;
}

@media screen and (max-width:1024px) {
    .racerbox-wrapper {
        padding: 15px;
    }

    .RacerBoxDetail {
        grid-template-columns: 100%;
    }

    .RacerBoxDetailRight {
        padding: 15px;
    }

    .RacerBox-rate ul {
        flex-direction: column;
    }

    .RacerBox-wrap {
        grid-template-columns: auto auto auto;
    }

    .RacerCarDetail {
        grid-template-columns: unset !important;
    }
    
    .RacerCar-infoDetail table {
        width: 100%;
        min-width: 100%;
    }

}


@media screen and (max-width:767px) {
    .racerbox-wrapper {
        padding: 15px;
    }

    .RacerBoxDetail {
        grid-template-columns: 100%;
    }

    .RacerBoxDetailRight {
        padding: 15px;
    }

    .RacerBox-rate ul {
        flex-direction: column;
    }

    .RacerBox-wrap {
        grid-template-columns: auto auto auto;
    }

    .RacerCarDetail {
        grid-template-columns: unset !important;
    }
    
    .RacerCar-infoDetail table {
        width: 100%;
        min-width: 100%;
    }

    .boxDetail-threeCol {
        grid-template-columns: 100%;
        width: 100%;
    }
    
    .boxDetail-threeCol .box-text {
        width: 100%;
    }

    .RacerBoxDetailLeft{
        padding: 15px;
    }

}