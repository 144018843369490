.racerbox-wrapper .box-title-main span{
    display: block;
    color: #fff;
}

.racerbox-wrapper{
    padding-top: 6.25rem;
}

.text-primary{
    color: var(--primary-color) !important;
}

.racerBox-row{
    margin-top: 130px;
}

.racerbox-wrapper .box {
    font-weight: 600;
    padding: 20px;
    /* background: linear-gradient(180deg, #102049 0%, rgba(16, 32, 73, 0) 100%); */
    border: 1px solid #f8a31b88;
    box-shadow: 0 0 10px #f8a31b88;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 30px;
    border-radius: 5px;
}

.racerbox-wrapper .box h3{
    font-size: 1.25rem;
}

.racerbox-wrapper .box .box-icon {
    width: 60px;
    height: 60px;
    background: linear-gradient(0deg, #39CFF4 -133.98%, rgba(57, 207, 244, 0) 124.74%);
    border-radius: 60px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.racerbox-wrapper .racerBox{
    background: #191919;
    border: 1px solid #f8a31b88;
    box-shadow: 0 0 10px #f8a31b88;
    padding: 20px;
    border-radius: 5px;
}

.racerbox-wrapper .racerBox-img{
    margin-top: -120px;
    min-height: 200px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.racerbox-wrapper .racerBox-img img{
    width: 75%;
}

/* .racerbox-wrapper .commonBox .racerBox-img{
    background-color: #2788C0;
}

.racerbox-wrapper .epicBox .racerBox-img{
    background-color: #4E63C3;
}

.racerbox-wrapper .lengendaryBox .racerBox-img{
    background-color: #884E33;
} */

.racerbox-wrapper .racerBox-text{
    margin-top: 30px;
}

.racerbox-wrapper .racerBox-text h3{
    text-align: center;
}

.racerBoxPrice{
    margin-top: 10px;
    position: relative;
    color: #fff;
    padding: 10px;
    z-index: 1;
    font-weight: 800;
}


@media screen and (max-width:767px) {
    .racerbox-wrapper{
        min-height: 100% !important;
        max-height: 100% !important;
        padding: 15px;
    }

    .racerbox-wrapper .box{
        gap: 40px;
    }
    
}

.racerBoxPrice img {
    width: 24px;
}