.main-wrapper {
    background-color: #120e1f;
    padding: 50px;
    display: flex;
    min-height: calc(100vh - 80px);
}

.racerConnect-wrapper{
    padding-top: 6.25rem;
    display: flex;
    flex-direction: row;
    width: 69.875rem;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.title_main {
    margin-top: 0;
    font-size: 24px;
    color: #F8A41B;
    border-bottom: 1px solid #ececec;
    margin-bottom: 20px;
    padding-bottom: 10px;
}

.main-wrapper .sidebar {
    flex: 0 0 30%;
}

.main-content span {
    display: block;
}

a.back-page {
    font-size: 16px;
    color: var(--white);
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
}

.text-primary {
    color: var(--primary-color);
}

.btn-connectMetaMask {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #fff !important;
    gap: 10px;
    text-transform: uppercase;
    background-color: var(--primary-color) !important;
    padding: 10px 20px;
    border-radius: 99px !important;
    border: 1px solid #f8a31b88;
}

.btn-connectMetaMask:hover{
    box-shadow: 0 0 10px #f8a31b88;
    color: var(--primary-color) !important;
    background-color: transparent !important;
}


.btn-connectMetaMask img {
    width: 30px;
}

.btn-connectMetaMask:hover {
    background-color: transparent !important;
    color: var(--white) !important;
    border: 1px solid var(--primary-color);
}

a.back-page:hover span,
a.back-page:hover {
    color: var(--primary-color);
}

.market-place-button {
    text-transform: uppercase;
    position: relative;
    height: 40px;
    border-radius: 99px;
    border: 1px solid var(--primary-color);
    display: flex !important;
    align-items: center;
    justify-content: center;
    transition: .4s ease;
}

.market-place-button:hover::before {
    border: 1px solid;
    border-color: var(--primary-color);
    background-color: transparent;
}

.market-place-button span {
    margin-bottom: 0;
}

.market-place-button:hover{
    background-color: transparent;
    box-shadow: 0 0 5px var(--primary-color);
}


.btn-icon:hover {
    color: var(--white);
}

.btn-icon:hover::before {
    border: 1px solid;
    border-color: var(--primary-color);
    background-color: transparent;
}

.btn-icon img {
    width: 30px;
}

.popUp_GameProfile {
    width: 700px;
    position: relative;
    border-radius: 10px;
    background-color: #000;
    border: 1px solid #f8a31b88;
    box-shadow: 0 0 10px #f8a31b88;
    overflow: hidden;
}

.popUp_ForgotPassWord{
    width: 500px;
    position: relative;
    border-radius: 10px;
    background-color: #000;
    border: 1px solid #f8a31b88;
    box-shadow: 0 0 10px #f8a31b88;
}

@media screen and ( max-width: 40em){
    .popUp_ForgotPassWord{
        width: 90%;
    }
}

.text-white label,
.text-white input,
.text-white label:hover {
    color: white;
}

.text-white input {
    padding: 5px;
    min-height: 40px;
}

.text-white fieldset,
.text-white fieldset:hover {
    border-color: #fff;
}

.p5 {
    padding: 20px;
}

.btn-sendCodeEmail {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    background-color: var(--primary-color) !important;
    color: #000 !important;
}

.btn-sendCodeEmail:hover {
    color: #fff !important;
}

.btn-CreateAccount{
    width: 100%;
    height: 45px;
    border-radius: 99px !important;
    background-color: var(--primary-color) !important;
    margin-bottom: 20px;
    border: 1px solid #f8a31b88;
}

.btn-CreateAccount:hover{
    box-shadow: 0 0 10px #f8a31b88;
    color: var(--primary-color) !important;
    background-color: transparent !important;
}

.btn-forgotPassword {
    background-color: transparent !important;
    border: none !important;
    color: #9d6407 !important;
    margin-bottom: 20px;
}

.btn-forgotPassword:hover{
    color: var(--primary-color) !important;
}

.btn-forgotPassword:focus{
    outline: none !important;
    border: none !important;
}

.ip_password {
    width: 100%;
    margin-top: 20px !important;
}

.ip_password input {
    padding: 5px 10px;
    min-height: 40px;
}

.loading-wrapper {
    position: fixed;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgb(19 11 33 / 46%);
    backdrop-filter: blur(32px);
    z-index: 999;
    font-family: 'Poppins', sans-serif;
}

.lds-roller {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.lds-roller div {
    animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 40px 40px;
}

.lds-roller div:after {
    content: " ";
    display: block;
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: var(--primary-color);
    margin: -4px 0 0 -4px;
}

.lds-roller div:nth-child(1) {
    animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
    top: 63px;
    left: 63px;
}

.lds-roller div:nth-child(2) {
    animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
    top: 68px;
    left: 56px;
}

.lds-roller div:nth-child(3) {
    animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
    top: 71px;
    left: 48px;
}

.lds-roller div:nth-child(4) {
    animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
    top: 72px;
    left: 40px;
}

.lds-roller div:nth-child(5) {
    animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
    top: 71px;
    left: 32px;
}

.lds-roller div:nth-child(6) {
    animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
    top: 68px;
    left: 24px;
}

.lds-roller div:nth-child(7) {
    animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
    top: 63px;
    left: 17px;
}

.lds-roller div:nth-child(8) {
    animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
    top: 56px;
    left: 12px;
}

@keyframes lds-roller {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.btn-linkAccount{
    color: #fff !important;
}

@media screen and ( max-width: 767px ) {
    .sidebar {
        padding: 10px 20px;
    }
    
    .main-content {
        padding: 10px 20px;
    }

    .popUp_GameProfile {
        width: 90%;
    }

    .popUp_GameProfile img {
        display: none;
    }
}