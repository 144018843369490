.CarProducts {
    --spacing: 30px;
    --column: 4;
    display: grid;
    grid-template-columns: repeat(var(--column), calc((100% - (var(--spacing) *3)) / 4));
    gap: var(--spacing);
}

.CarFilterBox {
    margin-bottom: 30px;
}

.CarFilterBoxTitle {
    border-bottom: 1px solid #ececec;
    margin-bottom: 20px;
}

.FilterTitle {
    font-size: 18px;
    font-family: 'Poppins', sans-serif;
}

.FilterNotifi {
    display: block;
    font-size: 14px;
}

.btn-chooseCar {
    width: 100%;
    border-radius: 99px;
    border: 1px solid #f8a31b88;
    margin-top: 20px;
    background-color: var(--primary-color);
}

.btn-chooseCar:hover{
    box-shadow: 0 0 10px #f8a31b88;
    color: var(--primary-color);
    background-color: transparent;
}

.checkBoxFilter {
    color: #2788C0 !important;
}

.RangerPrice * {
    color: var(--primary-color);
}

.ShortByCar {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 40px;
    color: #fff;
}

.ShortByCar>div {
    width: 20%;
}

.ShortByCar label {
    color: var(--primary-color);
}

.ShortByCar svg {
    color: var(--primary-color);
}


@media screen and (max-width: 40em) {
    .ShortByCar>div{
        width: 50%;
    }

    .slider-ranger{
        margin: 0 auto;
        width: 75% !important;
    }
}


.racer-textContent .racer-price img {
    width: 25px;
}

.racer-textContent .racer-price span.usd {
    font-family: 'Inter';
    font-weight: 400;
    font-size: 14px;
}

.popUpWrapper_ChooseCar span {
    font-size: 14px;
}

.popUpWrapper_ChooseCar .d-flex {
    width: 100%;
    gap: 20px;
}

.listCarType {
    display: grid;
    grid-template-columns: auto auto auto auto auto auto;
    gap: 10px;
    width: 100%;
    height: 250px;
    overflow-y: scroll;
}

.listCarType::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #F5F5F5;
}

.listCarType::-webkit-scrollbar {
    width: 5px;
    background-color: #F5F5F5;
}

.listCarType::-webkit-scrollbar-thumb {
    background-color: #F90;
    background-image: -webkit-linear-gradient(45deg,
            rgba(255, 255, 255, .2) 25%,
            transparent 25%,
            transparent 50%,
            rgba(255, 255, 255, .2) 50%,
            rgba(255, 255, 255, .2) 75%,
            transparent 75%,
            transparent)
}


.listCarType .CarTypeItem {
    background-color: #f8a41a0f;
    border-radius: 5px;
    position: relative;
}

.listCarType .CarTypeItem .CarTypeItem_check {
    position: absolute;
    top: 2px;
    right: 2px;
}

.listCarType .CarTypeItem .CarTypeItem_check svg {
    width: 20px;
    height: 20px;
    z-index: 100;
    border-radius: 5px;
    background-color: #f8a41a;
    color: #fff;
}

.listCarType .CarTypeItem img {
    width: 80px;
    height: 80px;
    object-fit: contain;
}

.listCarType .CarTypeItem span {
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 8px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    padding: 3px;
}

.btn-apply {
    min-width: 100px;
    border-radius: 99px;
    border: 1px solid #f8a31b88;
  
    background-color: var(--primary-color);
}

.btn-apply:hover{
    box-shadow: 0 0 10px #f8a31b88;
    background-color: transparent;
    color: var(--primary-color);
}

.CarFilterBoxContent_Wrap {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
}

.CarFilterBoxContent_Wrap .chooseCarImg{
    flex: 0 0 60px;
}

.clear{
    cursor: pointer;
}

.racermarket-wrapper {
    padding-top: 6.25rem;
    display: flex;
    flex-direction: row;
    width: 69.875rem;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.rarity-wrap {
    flex-direction: column !important;
}

.racer-car {
    background-size: cover;
    padding: 20px;
    min-height: 186px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}

.racer-item-detail .racer-car img{
    max-height: 140px;
    height: 140px;
}

@media screen and (max-width:767px) {
    .CarProducts {
        --spacing: 30px;
        --column: 1;
        grid-template-columns: auto auto;
    }

    .racerbox-wrapper2 {
        padding: 15px;
    }

    .racer-item .racer-level {
        width: 50px;
        height: 50px;
    }

    .racer-item .racer-level * {
        font-size: 12px !important;
    }

    .racer-textContent .racer-name,
    .racer-textContent .racer-price {
        font-size: 14px;
    }

    .header2 {
        position: unset;
    }

    .pr-xs-0{
        padding-right: 0 !important;
        padding-left: 0 !important;
    }

    .grid-mobile {
        margin-left: 0 !important;
        width: 100% !important;
    }
    .racermarket-wrapper{
        padding-left: 15px;
        padding-right: 15px;
    }
}