.account-wrapper {
    padding-top: 6.25rem;
    width: 69.875rem;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.account-wrapper .sidebar {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    border: 1px solid #f8a31b88;
    box-shadow: 0 0 10px #f8a31b88;
    padding: 40px 0px;
    border-radius: 5px;
}

.account-wrapper .main-content {
    flex: 0 0 70%;
}

.info-account img {
    width: 160px;
    margin-bottom: 10px;
}

.info-account .name {
    text-transform: uppercase;
}

.info_name label,
.info_email label {
    margin-bottom: 0;
}

.wallet-adress {
    display: flex;
    align-items: center;
    gap: 15px;
}

.wallet-adress .copy {
    color: var(--white);
    cursor: pointer;
}

.account-settings {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0px 40px;
    margin-bottom: 20px;
}

.info-account .btn-icon {
    margin: 0 auto;
    margin-top: 30px;
}

.btn-changename {
    background-color: var(--primary-color) !important;
    border-radius: 0 !important;
}

.tabs-account {
    display: flex;
    flex-direction: column;
}

.tabs-account .react-tabs__tab {
    width: 100%;
    border: none;
    border-radius: 0;
    padding: 15px 30px;
}

.tabs-account .react-tabs__tab span {
    font-size: 18px;
    display: flex;
    align-items: center;
    gap: 20px;
}

.react-tabs__tab:focus {
    box-shadow: none;
}

.react-tabs__tab:focus:after {
    display: none;
}

.tabs-account .react-tabs__tab--selected span {
    color: var(--primary-color);
}

.tabs-account .react-tabs__tab--selected {
    background-color: #191919;
    transition: .4s ease;
}

.info_name .name,
.info_email .email {
    margin-top: 5px;
    background-color: #191919;
    position: relative;
    padding: 10px 20px;
    border-radius: 5px;
}

.info_name .name span,
.info_email .email span {
    margin-bottom: 0;
}

.info_email .email img {
    width: 100px;
    height: auto;
    object-fit: contain;
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
}

.info_name,
.info_email {
    width: 100%;
    margin-bottom: 30px;
}

.filter-content,
.filter-content fieldset,
.filter label,
.filter svg {
    color: var(--white) !important;
}

#selected-item {
    padding-top: 10px;
    padding-bottom: 10px;
}

.filter-content fieldset {
    border-color: var(--white) !important;
}

.tabs-inventory {
    padding-bottom: 10px;
    border-bottom: 1px solid #fff;
}

.tabs-inventory li {
    color: var(--primary-color);
    padding: 10px 20px;
    border-radius: 99px;
    font-weight: 600;
    overflow: hidden;
}

.tabs-inventory li.react-tabs__tab--selected {
    background: transparent;
    border: none;
    position: relative;
    z-index: 1;
    color: #fff;
}

.tabs-inventory li.react-tabs__tab--selected:before {
    content: "";
    background-color: var(--primary-color);
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
    right: 0;
    margin: 0 auto;
    opacity: 1;
    transition: .4s ease;
    border-radius: 5px;
}

.tabs-inventory li span {
    margin-bottom: 0;
}

.filter_car {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0px;
}

.filter_car .total_car {
    font-size: 20px;
    font-weight: 600;
    text-transform: uppercase;
    color: #fff;
}

.filter_car .filter select {
    position: relative;
    padding: 10px 20px;
    border-radius: 10px;
    background-color: transparent;
    color: var(--primary-color);
    border-color: var(--primary);
}

.list-racers {
    --spacing: 30px;
    --column: 3;
    display: grid;
    grid-template-columns: repeat(var(--column), calc((100% - (var(--spacing) * 2)) / 3));
    gap: var(--spacing);
}

.wallet-wrapper {
    --spacing: 30px;
    --column: 3;
    display: grid;
    grid-template-columns: repeat(var(--column), calc((100% - (var(--spacing))) / 2));
    gap: var(--spacing);
}

.wallet-wrapper .wallet-box {
    background-color: #191919;
    padding: 15px;
    border-radius: 5px;
}

.wallet-wrapper .wallet-boxIcon {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 30px;
    position: relative;
}

.wallet-qr {
    position: absolute;
    right: 14px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    background-color: #f8a41d;
    border-radius: 99px;
    bottom: 87px;
    cursor: pointer;
}

.wallet-wrapper .wallet-box .money {
    margin-top: 20px;
}

.wallet-wrapper .wallet-box h2 {
    margin-bottom: 0;
    font-size: 18px;
}

.wallet-wrapper .wallet-box span {
    font-size: 16px;
}

.wallet-boxIconRight img {
    width: 40px;
}

.wallet-boxIcon .d-flex {
    justify-content: space-between;
    flex-wrap: nowrap;
}

.racer-item {
    position: relative;
    border-radius: 10px;
    cursor: pointer;
    transition: .4s all;
}

.racer-item:hover{
    transform: translateY(-10px);
}

.racer-item .racer-level {
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 25px;
    left: 12px;
    gap: .3rem;
    padding: 2px 5px;
    border-radius: 5px;
    background-color: var(--white);
    border: 1px solid #f8a31b88;
    box-shadow: 0 0 10px #f8a31b88;
}

.racer-item .racer-level * {
    margin: 0;
}

.racer-item .racer-level span,
.racer-item .racer-level h3 {
    font-size: 14px;
     color: var(--primary-color);
}

.racer-price span {
    margin-bottom: 0px;
}

.racer-textContent {
    text-align: center;
}

.racer-textContent .racer-name {
    font-size: 18px;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 5px;
}

.racer-textContent .racer-name .selling {
    font-size: 10px;
    display: inline-flex;
    align-items: center;
}

.racer-textContent .racer-price {
    font-size: 18px;
    font-weight: 600;
    padding: 5px;
    color: var(--dark);
    background-color: var(--primary-color);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.racer-textContent .racer-price .d-flex {
    gap: 5px;
}

.racer-textContent .racer-price span {
    color: #000;
    line-height: 20px;
}

.popUp {
    position: fixed;
    z-index: 999;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #0c0c0c7a;
    transition: all 500ms ease-in-out;
    backdrop-filter: blur(32px);
}

.popUp_Content {
    width: 600px;
    max-width: 600px;
    padding: 30px;
    border-radius: 10px;
    background-color: #000;
    border: 1px solid #f8a31b88;
    box-shadow: 0 0 10px #f8a31b88;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    position: relative;
    overflow: hidden;
}

.popUp_Content h3 {
    margin: 0;
    font-size: 20px;
}

.close-popUp {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
    color: #fff !important;
    background-color: var(--primary-color);
    border-radius: 40px;
}

.btn-linkAccount,
.btn-createAccount,
.btn-desposit {
    position: relative;
    background-color: var(--primary-color) !important;
    z-index: 1;
    border: 1px solid #f8a31b88 !important;
    padding: 10px 20px;
    border-radius: 99px !important;
    cursor: pointer;
    box-shadow: none;
}

.btn-createAccount {
    color: #fff !important;
    min-width: 240px;
}

.btn-linkAccount:hover{
    box-shadow: 0 0 10px #f8a31b88;
    background-color: transparent !important;
}

.btn-desposit,
.btn-claim {
    background-color: var(--primary-color) !important;
    color: var(--white) !important;
    align-items: center;
    line-height: auto !important;
}

.btn-desposit:hover,
.btn-claim:hover{
    background-color: transparent !important;
    box-shadow: 0 0 5px var(--primary-color);
    color: var(--white);
}

.btn-notClaim {
    background-color: #5e5e5e !important;
    color: var(--white) !important;
    line-height: auto !important;
}

.btn-linkAccount:hover,
.btn-createAccount:hover,
.btn-desposit:hover {
    color: #fff !important;
}

.text-center {
    text-align: center;
}

.react-tabs__tab-panel h3 {
    font-size: 20px;
}

ul.paginate-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
}

.paginate-wrap .previous,
.paginate-wrap .next {
    padding: 5px 10px;
    background-color: var(--primary-color);
    border-radius: 5px;
}

.paginate-wrap svg {
    color: var(--white);
}

.paginate-wrap li a {
    color: var(--white) !important;
}

.paginate-wrap .selected {
    color: var(--primary-color) !important;
}

.data-notFound img {
    width: 100px;
}

.data-notFound {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.data-notFound span {
    margin-bottom: 0;
    font-weight: 600;
}

.myRacerbox {
    display: grid;
    grid-template-columns: 30%;
}

.myRacerbox .racerBox {
    background-color: #191919;
    border-radius: 5px;
    padding: 10px;
}

.myRacerbox .racerBox .racerBox-img {
    margin-bottom: 20px;
}

.btn-openBox {
    background-color: #ececec !important;
    padding: 10px;
    width: 100%;
    display: block;
    border-radius: 99px;
    text-align: center;
    font-weight: 600;
    margin-top: 20px;
}

.btn-openBox span {
    color: #000;
}

.info_name .name {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.info_name .name .btn-changeName {
    background-color: var(--primary-color);
}

fieldset {
    border-color: var(--white) !important;
}

label,
input {
    color: var(--white) !important;
}

#outlined-basic {
    width: 100%;
}

.w100 div,
.w100 {
    width: 100% !important;
}

.noti-item {
    width: 100%;
}

.noti-item>div {
    padding: 0;
}

.error {
    color: red;
    width: 48px;
    height: 48px;
}

.success {
    color: green;
    width: 48px;
    height: 48px;
}

.loading-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 30%;
    text-align: center;
    font-family: 'Poppins', sans-serif;
}

.loading-container .please-wait-text {
    font-family: 'Poppins', sans-serif;
    font-size: 13px;
    color: var(--primary-color);
}

.popUp_claim {
    width: 400px;
    max-width: 400px;
}

.forgotPassword span {
    display: block;
}

@media screen and (max-width:767px) {
    .popUp_LinkToAccount {
        width: 75%;
        max-width: 75%;
    }

    .account-wrapper {
        padding: 15px;
        height: auto;
    }

    .account-wrapper>.react-tabs {
        grid-template-columns: unset;
    }

    .info_name,
    .info_email {
        width: 100%;
    }

    .info_name .name button {
        right: 0;
    }

    .popUp_LinkToAccount h3 {
        font-size: 20px;
    }

    .list-racers {
        grid-template-columns: 45% 45%;
        height: auto;
        justify-content: space-between;
    }

    .wallet-wrapper {
        grid-template-columns: auto;
    }

    .react-tabs__tab-panel h3 {
        font-size: 20px;
    }

    .wallet-wrapper .wallet-box h2 {
        font-size: 22px;
    }

    .btn-desposit {
        width: 100%;
    }

    .tabs-inventory li {
        width: 100%;
    }

}

@media screen and (max-width: 40em) {
    .popUp_LinkToAccount {
        width: 90%;
        max-width: 90%;
    }
}

.example::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.example {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.icon-token {
    gap: 5px;
}

.icon-token img {
    width: 26px;
}

.iconToken-wrap {
    padding: 20px;
    background-color: #191919;
    border-radius: 99px;
    gap: 10px;
}

input.amout {
    background-color: transparent;
    box-shadow: none;
    border: none;
    width: 55%;
}

.iconToken-wrap input.amout:focus {
    outline: none;
    border: none !important;
    box-shadow: none !important;
}

.btn.btn-max {
    background-color: #ececec;
    height: 26px;
    display: flex;
    align-items: center;
}

.iconToken-box {
    width: 100%;
    margin-top: 20px;
}

.iconToken-wrap {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}


.btn-confirm {
    height: 66px;
    background-color: var(--primary-color) !important;
    border-radius: 10px;
}

.box-emrs {
    width: 100%;
    padding: 20px;
    background-color: #191919;
    border-radius: 10px;
}

.token-info-emrs {
    padding: 10px;
    border-radius: 10px;
    margin-top: 15px;
    background: #191919;
    margin-bottom: 15px;
    width: 100%;
}

.token-info-emrs span {
    display: block;
    text-align: left;
    font-size: 12px;
    margin-bottom: 10px;
}

.font-12 {
    font-size: 12px;
    display: block;
}


.token-info-emrs span:last-child {
    margin-bottom: 0;
}

.popUp_claim h3 {
    margin-bottom: 20px;
}

.btn-claim,
.btn-claim-wrap {
    margin-top: 20px;
    text-transform: uppercase;
}

.btn-claim-wrap {
    width: 100%;
    text-transform: uppercase;
    background-color: var(--primary-color) !important;
    color: #fff !important;
}

.deposit-wrap {
    width: 100%;
}

.font-16 {
    font-size: 13px;
}

.racer-car img {
    max-height: 220px;
}

.text-truncate-line-one {
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.deposit-wrap input.amout {
    flex: 0 0 75%;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.showHourGlass img {
    width: 150px;
    margin-bottom: 20px;
}

.showHourGlass h3 {
    margin-bottom: 5px;
}

.showHourGlass span {
    font-size: 14px;
}

.referral-box {
    background-color: #191919;
    padding: 15px;
    border-radius: 5px;
}

.referral-box-title {
    font-size: 20px;
    color: var(--primary-color);
    margin-bottom: 10px;
}

.referral-box-input {
    border: 1px solid var(--primary-color);
    background-color: transparent;
    box-shadow: none !important;
    width: 100%;
    border-radius: 8px;
    padding: 5px 10px;
    margin-top: 10px;
}

.referral-box-input:focus {
    border: 1px solid var(--primary-color) !important;
    background-color: transparent;
    box-shadow: none !important;
}

.btn-register {
    margin-top: 10px;
    background-color: #f8a41a !important;
    width: 100%;
    color: #000 !important;
}

.btn-register:hover{
    background-color: transparent !important;
    box-shadow: 0 0 5px var(--primary-color);
    color: var(--primary-color) !important;
}

.referral-box-info {
    margin-top: 20px;
    background-color: #000;
    position: relative;
    padding: 10px 20px;
    border-radius: 5px;
}

.referral-box-info .name {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.referral-box-info .name span {
    word-wrap: break-word;
}

.referral-box-info .name svg {
    color: #f8a41a;
}

.referral-box-info th,
.referral-box-info td {
    color: #fff;
}

.btn-accept {
    background-color: #f8a41a !important;
}

.referral-box-info table tbody td {
    vertical-align: middle;
    text-align: center;
}

.referral-box-info table thead th {
    text-align: center;
}

.btn-accept {
    color: #000 !important
}

.farms-list-item {
    background-color: #191919 !important;
    border-bottom: 1px solid #ececec;
}

.box-header-list {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    gap: 30px;
    align-items: center;
    margin-right: 10px;
}

.box-text .text-large {
    font-weight: 600;
    font-size: 18px;
}

.box-text span:not(span.text-large) {
    font-size: 13px;
    color: var(--white);
}

.box-text-earned .text {
    font-size: 14px;
}

.box-text-earned .earn-total {
    font-size: 18px;
    font-weight: 600;
    color: #f8a41a;
}

.box-text-apr span.text-large {
    font-size: 14px;
}

.box-text-apr span.number {
    font-weight: 600;
    font-size: 15px;
}

.box-text-staked span.text-large {
    font-size: 14px;
}

span.number-total-staked {
    font-size: 15px;
    font-weight: 600;
}

.box-text-end span.text-large {
    font-size: 14px;
}

.icon-expand {
    color: #f8a41a;
}

.box-content-wrapper {
    border-top: 1px solid #ececec;
    background-color: #191919;
    padding: 20px 16px !important;
}

.text-view-info>div span:last-child {
    color: #f8a41a;
    font-weight: 600;
}

.text-view-info:first-child {
    margin-bottom: 20px;
}

.text-view-info:not(:first-child) {
    margin-bottom: 5px;
    font-size: 15px;
}

.box-harvest {
    border-left: 1px solid #ababab52;
    border-right: 1px solid #ababab52;
    padding: 0 15px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 100%;
    justify-content: space-between;
}

.harvest-left .total-coin span {
    color: #f8a41a;
    font-weight: 600;
}

.harvest-left .total-coin {
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
}

.total-usd {
    display: flex;
    align-items: center;
    gap: 5px;
}

.total-usd span,
.total-usd {
    color: #f8a41a;
}

.btn-harvest {
    background-color: #f8a41a !important;
}

.box-staking .total-coin span {
    color: #f8a41a;
    font-weight: 600;
}

.box-staking .btn-minus.btn {
    background-color: #f8a41a;
    flex: 1;
}

.box-staking .btn-plus.btn {
    background-color: #f8a41a;
    margin-left: 10px;
    color: #fff;
    flex: 1;
}

.box-staking {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
}

.box-staking .btn-connectWallet {
    background-color: #f8a41a;
}

.text-view-info {
    display: flex;
    align-items: center;
    gap: 5px;
}

.convert-wrapper .iconToken-wrap {
    background-color: black;
}

.convertBoxIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    margin: 0 auto;
    color: white;
    background: #f8a41d;
    border-radius: 99px;
    margin-top: 20px;
}

.history-qr {
    bottom: 140px;
}

.popU_history {
    width: 600px;
    max-width: 600px;
}

.popU_history table td span img {
    width: 16px;
}

.popU_history table * {
    font-size: 14px;
}

.popUp_claim .iconToken-wrap.deposit-wrap {
    background-color: #191919;
}

@media screen and (max-width:767px) {
    .racer-car img {
        max-height: 150px;
    }

    .referral-box-info .name svg {
        width: 100px;
    }

    .referral-box-info .table th {
        font-size: 12px;
    }

    .account-wrapper .main-content {
        padding: 15px 0px;
    }

    .referral-box-info {
        padding: 10px;
    }

    .referral-box-info table thead th {
        min-width: 120px;
    }

    .referral-box-info table thead th:first-child {
        min-width: 50px;
    }

    .referral-box-info .name {
        align-items: unset;
        flex-direction: column;
    }

    .referral-box-info .name svg {
        flex: 0 0 24px;
        width: 24px;
    }

    .box-header-list {
        justify-content: space-between;
        align-items: flex-start;
        gap: 10px;
        margin-right: 0;
        flex-wrap: wrap;
    }
    
    .box-header-list .box-header-part {
        width: 100%;
        border-bottom: 1px solid #ececec26;
        padding-bottom: 5px;
        flex: 0 0 45%;
    }
    
    .box-harvest {
        border-top: 1px solid #ababab52;
        border-bottom: 1px solid #ababab52;
        border-left: 0;
        border-right: 0;
        padding: 20px 0;
    }
    
    .box-staking {
        gap: 10px;
    }
    
    .box-staking .total-coin span {
        font-size: 18px;
    }
    
    .harvest-left .total-coin span {
        font-size: 18px;
    }

}

