.OpenRacerDetailLeft img {
    width: 100% !important;
}

.RacerBoxDetailLeft {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.RacerBoxDetailLeft .box-quantily button {
    height: 50px;
}

.popUp_CheckoutCar .listCarOpen img {
    width: 75%;
    margin: 0 auto;
}

.success-noti {
    padding: 10px;
    width: 100%;
    border-radius: 10px;
}

.text-success {
    color: #95CD41;
}

.loading-wrapper.loading-Openbox {
    flex-direction: column;
}

.loading-Openbox p{
    text-transform: uppercase;
    font-family: 'Poppins', sans-serif;
}

.lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 30px;
}

.lds-ellipsis div {
    position: absolute;
    top: 10px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: var(--primary-color);
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
    0% {
        transform: scale(0);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes lds-ellipsis3 {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(0);
    }
}

@keyframes lds-ellipsis2 {
    0% {
        transform: translate(0, 0);
    }

    100% {
        transform: translate(24px, 0);
    }
}