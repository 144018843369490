.footer {
    background-color: #000;
    color: #fff;
    
}

.footer .box-section {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 60px 0;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

@media only screen and (max-width:991px) {
    .footer .box-section {
        padding: 0;
    }
}

.footer .box-section a {
    color: #fff;
    border-radius: 99px;
}

.footer .box-section a:hover {
    color: var(--primary);
}

.footer .box-section .item-footer {
    width: 100%;
    padding: 0 15px;
}

@media only screen and (max-width:767px) {
    .footer .box-section .item-footer {
        margin-bottom: 40px;
        width: 100%;
    }
}

.footer .box-section .item-footer.box-link {
    width: 40%;
}

@media only screen and (max-width:767px) {
    .footer .box-section .item-footer.box-link {
        width: 100%;
    }
}

.footer .box-section .logo-footer {
    margin: auto;
    margin-top: 30px;
    margin-bottom: 30px;
    width: 300px;
    height: 80px;
    object-fit: contain;
}

@media only screen and (max-width:991px) {
    .footer .box-section .logo-footer {
        height: 100px;
        margin-bottom: 20px;
    }
}

@media only screen and (max-width:767px) {
    .footer .box-section .logo-footer {
        margin-bottom: 15px;
    }
}

.footer .box-section .copyright {
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
    text-align: center;
}

@media only screen and (max-width:767px) {
    .footer .box-section .copyright {
        font-size: 14px;
    }
}

.footer .box-section .link-footer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.footer .box-section .link-footer .list-link {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    width: 33.33%;
}

.footer-dashboard .box-section {
    padding-top: 0;
}

@media only screen and (max-width:767px) {
    .footer .box-section .link-footer .list-link {
        width: 100%;
    }
}

.footer .box-section .link-footer .list-link a {
    display: block;
    text-transform: uppercase;
    margin-bottom: 35px;
    width: 100%;
}

@media only screen and (max-width:767px) {
    .footer .box-section .link-footer .list-link a {
        width: 50%;
        padding-left: 10px;
    }

    .section-full .box-section {
        background-size: cover;
    }
}

.footer .box-section .join-our {
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 10px;
}

.list-social {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.list-social li {
    list-style: none;
    padding: 0 10px
}

.list-social li a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 50px;
    transition: all .4s ease;
}

.list-social li a:hover{
    transform: scale(1.1);
}

.list-social li a i {
    font-size: 20px;
    color: #fff
}